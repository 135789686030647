<!-- clr-required-mark -->
<clr-modal
  [clrModalOpen]="true"
  [clrModalClosable]="true"
  (clrModalOpenChange)="openChange($event)"
>
  <h3 class="modal-title">
    <cds-icon shape="host" size="md"></cds-icon> Schaltanlage
  </h3>
  <div class="modal-body">
    <!-- <ng-container *ngIf="template"></ng-container> -->
    <form
      clrForm
      [formGroup]="formDynamisch"
      (ngSubmit)="onSubmitAnlage()"
      id="dynamicForm"
    >
      <ng-container *ngIf="template$ | async as template">
        <!-- <ng-container *ngIf="template"> -->
        <clr-input-container
          *ngFor="let anlageItem of getTemplateAnlageItems()"
        >
          <label class="clr-col-12 clr-col-md-4" for="dynamicForm">{{
            anlageItem.Title
          }}</label>
          <input
            #input
            class="clr-col-12 clr-col-md-8"
            clrInput
            [id]="anlageItem._id!"
            [formControlName]="anlageItem._id!"
            (keypress)="preventEnter($event, anlageItem)"
          />
          <!-- //"template._id!" -->
          <!-- [formControlName]="'anlage' + template.Index" -->
        </clr-input-container>
      </ng-container>
      <p></p>
      <button #submit type="submit" class="btn btn-primary">speichern</button>
    </form>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline" (click)="onCancel()">
      Cancel
    </button> -->
    <!-- <button type="button" class="btn btn-primary" (click)="onOkay()">Ok</button> -->
  </div>
</clr-modal>
