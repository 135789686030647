<clr-modal [clrModalOpen]="true" [clrModalClosable]="true">
  <h3 class="modal-title">
    <cds-icon shape="host" size="md"></cds-icon> File-Import
  </h3>
  <div class="modal-body">
    <form
      clrForm
      [formGroup]="formMobile"
      (ngSubmit)="onSubmitFile()"
      id="formFotos"
    >
      <div formGroupName="Image">
        <clr-control-container id="ctlImage">
          <label for="ctlImage">Datei</label>
          <input
            clrControl
            type="file"
            #fileUpload
            (change)="onFileSelected($event)"
            name="thumbnail"
            formControlName="Path"
          />
        </clr-control-container>
        <clr-input-container id="ctlName">
          <label class="clr-required-mark" for="ctlName">Name</label>
          <input type="text" clrInput formControlName="Name" required />
          <clr-control-helper>Dateiname</clr-control-helper>
          <clr-control-error
            >Dieses Feld muss ausgefüllt werden!</clr-control-error
          >
        </clr-input-container>
        <clr-control-container id="ctlProgress">
          <label for="ctlProgress">Progress</label>
          <div class="progress-block">
            <clr-progress-bar
              id="demoProgressBar"
              [clrValue]="uploadProgress"
              clrMax="100"
            ></clr-progress-bar>
            <span>&nbsp; {{ uploadProgress }} %</span>
          </div>
        </clr-control-container>
        <p></p>
      </div>
      <button class="btn btn-primary" type="submit">senden</button>
    </form>
  </div>
</clr-modal>
