import { Template } from './Template';
import { Cabinet } from './Cabinet';
import { Item } from './Item';

export interface BaseImage {
  _id: string;
  InternalName: string;
  Path: string;
  Name: string;
  Original: string;
  Extension: string;
  Encoding: string;
  MIME: string;
  Size: number;
  Thumb: any;
}

export interface AssesmentItem {
  Nr: number;
  Gefaehrdung: string;
  Schadensmass: string;
  Eintrittwahrscheinlichkeit: string;
  Risikobewertung: string;
  Anlage: string;
  Massnahmen: string;
  MethodeTOP: string;
  UmsetzenBis: Date;
  UmsetzungErfolgt: boolean;
  NameUnterschrift: string;
  SchadensmassNachMassnahme: string;
  EintrittwahrscheinlichkeitNachMassnahme: string;
  RisikobewertungNachMassnahme: string;
}

export class AssesmentItemObject implements AssesmentItem {
  public Nr: number;
  public Gefaehrdung: string;
  public Schadensmass: string;
  public Eintrittwahrscheinlichkeit: string;
  public Risikobewertung: string;
  public Anlage: string;
  public Massnahmen: string;
  public MethodeTOP: string;
  public UmsetzenBis: Date;
  public UmsetzungErfolgt: boolean;
  public NameUnterschrift: string;
  public SchadensmassNachMassnahme: string;
  public EintrittwahrscheinlichkeitNachMassnahme: string;
  public RisikobewertungNachMassnahme: string;

  constructor() {
    this.Nr = 0;
    this.Gefaehrdung = '';
    this.Schadensmass = '';
    this.Eintrittwahrscheinlichkeit = '';
    this.Risikobewertung = '';
    this.Anlage = '';
    this.Massnahmen = '';
    this.MethodeTOP = '';
    this.UmsetzenBis = new Date();
    this.UmsetzungErfolgt = false;
    this.NameUnterschrift = '';
    this.SchadensmassNachMassnahme = '';
    this.EintrittwahrscheinlichkeitNachMassnahme = '';
    this.RisikobewertungNachMassnahme = '';
  }

  // public setUmsetzungErfolgt(erfolgt: boolean) {
  //   this.UmsetzungErfolgt = erfolgt;
  // }

  // constructor(item: AssesmentItemObject) {
  //   this.Nr = item.Nr;
  //   this.Gefaehrdung = item.Gefaehrdung;
  //   this.Schadensmass = item.Schadensmass;
  //   this.Eintrittwahrscheinlichkeit = item.Eintrittwahrscheinlichkeit;
  //   this.Risikobewertung = item.Risikobewertung;
  //   this.Anlage = item.Anlage;
  //   this.Massnahmen = item.Massnahmen;
  //   this.MethodeTOP = item.MethodeTOP;
  //   this.UmsetzenBis = item.UmsetzenBis;
  //   this.UmsetzungErfolgt = item.UmsetzungErfolgt;
  //   this.NameUnterschrift = item.NameUnterschrift;
  //   this.SchadensmassNachMassnahme = item.SchadensmassNachMassnahme;
  //   this.EintrittwahrscheinlichkeitNachMassnahme =
  //   item.EintrittwahrscheinlichkeitNachMassnahme;
  //   this.RisikobewertungNachMassnahme = item.RisikobewertungNachMassnahme;
  // }
}

export interface PruefItem {
  Prueftiefe: string;
  RCDTest: string;
  RCDPruefung: string;
  Sichtpruefung: string;
  Detailpruefung: string;
  DetailpruefungReduktionsfaktor: string;
}

export interface Evaluation extends LowEvaluation {
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface LowEvaluation {
  Cabinet: Cabinet;
  Template: Template;
  Pruefung?: {
    ItemId: string;
    Value: object;
  }[];
  Gefaehrdung?: {
    ItemId: string;
    Value: object;
  }[];
  Page1: {
    Assesment: {
      Categorie: string;
      AssesmentItem: AssesmentItemObject[];
    }[];
  };
  Page2: {
    PruefItems: PruefItem[];
    Reduktionsfaktor: string;
  };
  PDFResult: {
    createdAt: Date;
    updatedAt: Date;
    File: string;
  };
  Images?: BaseImage[];
  createdAt?: Date;
  updatedAt?: Date;
}

export class EvaluationCalculations {
  Calculations: [{ value: number; id: string | undefined }] = [] as unknown as [
    { value: number; id: string },
  ];
  id_Schmutz: string | undefined = undefined;
  id_Feuchte: string | undefined = undefined;
  id_Alterng: string | undefined = undefined;
  id_Ausltng: string | undefined = undefined;
  id_Umgebungsbedingungen: string | undefined = undefined;
  Id_SummeBewertungsZahl: string | undefined = undefined; // Ergebnis
  Id_SchlechtesteBewertung: string | undefined = undefined; // Ergebnis
  Id_Redunktionsfaktor: string | undefined = undefined; // Ergebnis
  id_NI_L1L2: string | undefined = undefined;
  id_NI_L2L3: string | undefined = undefined;
  id_NI_L3L1: string | undefined = undefined;
  id_Kurzschlusstrom: string | undefined = undefined; // Ergebnis

  constructor(evaluation: Evaluation, template: Template) {
    try {
      this.id_Schmutz = template.Pruefung?.find((p) => p.Name === 'Schmutz')
        ?._id;
      this.id_Feuchte = template.Pruefung?.find(
        (p) => p.Name === 'Feuchtigkeit',
      )?._id;
      this.id_Alterng = template.Pruefung?.find((p) => p.Name === 'Alterung')
        ?._id;
      this.id_Ausltng = template.Pruefung?.find((p) => p.Name === 'Auslastung')
        ?._id;
      this.id_Umgebungsbedingungen = template.Pruefung?.find(
        (p) => p.Name === 'Umgebungsbedingungen',
      )?._id;
      this.Id_SummeBewertungsZahl = template.Pruefung?.find(
        (p) => p.Name === 'SummeBewertungsZahl',
      )?._id as string;
      this.Id_SchlechtesteBewertung = template.Pruefung?.find(
        (p) => p.Name === 'SchlechtesteBewertung',
      )?._id as string;
      this.Id_Redunktionsfaktor = template.Pruefung?.find(
        (p) => p.Name === 'Redunktionsfaktor',
      )?._id as string;
      this.id_NI_L1L2 = template?.Gefaehrdung?.find(
        (p) => p.Name === 'Netzimpedanz_L1L2',
      )?._id;
      this.id_NI_L2L3 = template?.Gefaehrdung?.find(
        (p) => p?.Name === 'Netzimpedanz_L2L3',
      )?._id;
      this.id_NI_L3L1 = template?.Gefaehrdung?.find(
        (p) => p.Name === 'Netzimpedanz_L3L1',
      )?._id;
      this.id_Kurzschlusstrom = template?.Gefaehrdung?.find(
        (p) => p.Name === 'Kurzschlusstrom',
      )?._id as string;
      if (evaluation) this.updateCalculation(evaluation);
      else console.error('EvaluationCalculations() evaluation:', evaluation);
    } catch (err) {
      console.error(err);
    }
  }

  containsCalculations(item: Item): boolean {
    try {
      const calcItm = this.Calculations?.find((cc) => cc.id === item._id);
      if (calcItm) return true;
    } catch (err) {
      console.error('FEHLER : ', err);
    }
    return false;
  }

  getCalculationFor(
    item: Item,
  ): { value: number; id: string | undefined } | undefined {
    let result = undefined as unknown as
      | { value: number; id: string | undefined }
      | undefined;
    try {
      result = this.Calculations?.find((calcItem) => calcItem.id === item._id);
    } catch (err) {
      console.error('FEHLER : ', err);
    }
    return result;
  }

  /**
   * will calculate new results by cabinet
   * @param evaluation Cabinet
   */
  updateCalculation(evaluation: Evaluation): void {
    if (!evaluation) {
      console.error(
        'ERROR updateCalculation(evaluation: Evaluation):',
        evaluation,
      );
      return;
    }
    try {
      const itm1Schmutz = evaluation?.Pruefung?.find(
        (p) => p.ItemId == this.id_Schmutz,
      );
      const itm2Feuchte = evaluation.Pruefung?.find(
        (p) => p.ItemId == this.id_Feuchte,
      );
      const itm3Alterng = evaluation.Pruefung?.find(
        (p) => p.ItemId == this.id_Alterng,
      );
      const itm4Ausltng = evaluation.Pruefung?.find(
        (p) => p.ItemId == this.id_Ausltng,
      );
      const itmUmgebungsbedingungen = evaluation.Pruefung?.find(
        (p) => p.ItemId == this.id_Umgebungsbedingungen,
      );
      const itmN_L1L2 = evaluation.Gefaehrdung?.find(
        (p) => p.ItemId == this.id_NI_L1L2,
      );
      const itmN_L2L3 = evaluation.Gefaehrdung?.find(
        (p) => p.ItemId == this.id_NI_L2L3,
      );
      const itmN_L3L1 = evaluation.Gefaehrdung?.find(
        (p) => p.ItemId == this.id_NI_L3L1,
      );
      let Value_SummeBewertungsZahl = NaN;
      let Value_SchlechtesteBewertung = NaN;
      let Value_Redunktionsfaktor = NaN;
      let Value_Kurzschlusstrom = NaN;
      const a1Schmutz = Number.parseInt(
        itm1Schmutz?.Value?.toString() as string,
        10,
      ) as number;
      const a2Feuchte = Number.parseInt(
        itm2Feuchte?.Value?.toString() as string,
        10,
      ) as number;
      const a3Alterng = Number.parseInt(
        itm3Alterng?.Value?.toString() as string,
        10,
      ) as number;
      const a4Ausltng = Number.parseInt(
        itm4Ausltng?.Value?.toString() as string,
        10,
      ) as number;
      const nichtsBesonderes =
        itmUmgebungsbedingungen?.Value?.toString() ==
        'keine besonderen Bedingungen';
      if (
        !Number.isNaN(a1Schmutz) &&
        !Number.isNaN(a2Feuchte) &&
        !Number.isNaN(a3Alterng) &&
        !Number.isNaN(a4Ausltng)
      ) {
        Value_SummeBewertungsZahl = (a1Schmutz +
          a2Feuchte +
          a3Alterng +
          a4Ausltng) as number;
      }
      if (!Number.isNaN(Value_SummeBewertungsZahl)) {
        Value_SchlechtesteBewertung = Math.max(
          a1Schmutz,
          a2Feuchte,
          a3Alterng,
          a4Ausltng,
        ) as number;
      }
      let sumFaktor = NaN as number;
      let maxFaktor = NaN as number;
      if (
        nichtsBesonderes &&
        !Number.isNaN(Value_SummeBewertungsZahl) &&
        Value_SummeBewertungsZahl !== undefined &&
        !Number.isNaN(Value_SchlechtesteBewertung) &&
        Value_SchlechtesteBewertung !== undefined
      ) {
        switch (true) {
          case Value_SummeBewertungsZahl < 13:
            sumFaktor = 1;
            break;
          case Value_SummeBewertungsZahl < 16:
            sumFaktor = 0.75;
            break;
          case Value_SummeBewertungsZahl < 19:
            sumFaktor = 0.5;
            break;
          default:
            sumFaktor = 0.25;
        }
        switch (true) {
          case Value_SchlechtesteBewertung < 4:
            maxFaktor = 1;
            break;
          case Value_SchlechtesteBewertung < 5:
            maxFaktor = 0.75;
            break;
          case Value_SchlechtesteBewertung < 6:
            maxFaktor = 0.5;
            break;
          default:
            maxFaktor = 0.25;
        }
        Value_Redunktionsfaktor = Math.min(sumFaktor, maxFaktor);
      } else if (
        !Number.isNaN(Value_SummeBewertungsZahl) &&
        Value_SummeBewertungsZahl !== undefined &&
        !Number.isNaN(Value_SchlechtesteBewertung) &&
        Value_SchlechtesteBewertung !== undefined
      ) {
        switch (true) {
          case Value_SummeBewertungsZahl < 16:
            sumFaktor = 1;
            break;
          case Value_SummeBewertungsZahl < 19:
            sumFaktor = 0.75;
            break;
          default:
            sumFaktor = 0.5;
        }
        switch (true) {
          case Value_SchlechtesteBewertung < 5:
            maxFaktor = 1;
            break;
          case Value_SchlechtesteBewertung < 6:
            maxFaktor = 0.75;
            break;
          default:
            maxFaktor = 0.5;
        }
        Value_Redunktionsfaktor = Math.min(sumFaktor, maxFaktor);
      }
      const ni1 = Number.parseFloat(itmN_L1L2?.Value?.toString() as string);
      const ni2 = Number.parseFloat(itmN_L2L3?.Value?.toString() as string);
      const ni3 = Number.parseFloat(itmN_L3L1?.Value?.toString() as string);
      try {
        if (!Number.isNaN(ni1) && !Number.isNaN(ni2) && !Number.isNaN(ni3)) {
          Value_Kurzschlusstrom =
            (1.1 * 400) /
            ((Math.sqrt(3) *
              Math.min(
                (ni1 - ni3 + ni2) / 2,
                (ni1 + ni3 - ni2) / 2,
                (-ni1 + ni3 + ni2) / 2,
              )) /
              1.15);
        }
      } catch (err) {
        console.error(err);
        Value_Kurzschlusstrom = NaN;
      }

      const sumBewertungsZahl = this.Calculations.find(
        (c) => c.id == this.Id_SummeBewertungsZahl,
      );
      if (sumBewertungsZahl) {
        sumBewertungsZahl.value = Value_SummeBewertungsZahl;
      } else {
        this.Calculations.push({
          value: Value_SummeBewertungsZahl,
          id: this.Id_SummeBewertungsZahl,
        });
      }
      const schlechtesteBewertung = this.Calculations.find(
        (c) => c.id == this.Id_SchlechtesteBewertung,
      );
      if (schlechtesteBewertung) {
        schlechtesteBewertung.value = Value_SchlechtesteBewertung;
      } else {
        this.Calculations.push({
          value: Value_SchlechtesteBewertung,
          id: this.Id_SchlechtesteBewertung,
        });
      }
      const redunktionsfaktor = this.Calculations.find(
        (c) => c.id == this.Id_Redunktionsfaktor,
      );
      if (redunktionsfaktor) {
        redunktionsfaktor.value = Value_Redunktionsfaktor;
      } else {
        this.Calculations.push({
          value: Value_Redunktionsfaktor,
          id: this.Id_Redunktionsfaktor,
        });
      }
      const kurzschlusstrom = this.Calculations.find(
        (c) => c.id == this.id_Kurzschlusstrom,
      );
      if (kurzschlusstrom) {
        kurzschlusstrom.value = Value_Kurzschlusstrom;
      } else {
        this.Calculations.push({
          value: Value_Kurzschlusstrom,
          id: this.id_Kurzschlusstrom,
        });
      }
    } catch (err) {
      console.error('FEHLER : ', err);
    }
  }
}
