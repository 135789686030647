<div
  class="login-wrapper"
  style="background-image: url(/assets/images/{{ background }});"
>
  <form class="login" [formGroup]="userEmails" (ngSubmit)="signup()">
    <section class="title">
      <h3 class="welcome">
        <img src="assets/logo-c.png" height="24" alt="Vrielmann GmbH" />
        Vrielmann Connect
      </h3>
      Herzlich Willkommen
      <h5 class="hint">
        <cds-icon shape="login"></cds-icon> Registrierung &nbsp;
        <!-- <cds-icon shape="resistor"></cds-icon> &nbsp; -->
        <!-- <cds-icon shape="inductor"></cds-icon> &nbsp; -->
        <!-- <cds-icon shape="capacitor"></cds-icon> &nbsp; -->
      </h5>
    </section>

    <div class="login-group">
      <!-- Username -->
      <clr-input-container>
        <label for="username">E-Mail Adresse</label>
        <input
          clrInput
          [class.is-invalid]="username?.invalid && username?.touched"
          type="email"
          id="username"
          name="username"
          autocomplete="username"
          placeholder=""
          formControlName="username"
        />
        <clr-control-error>
          <!-- <div
            *ngIf="(username?.invalid && username?.touched) || username?.dirty"
          > -->
          <small *ngIf="username?.errors?.['required']" class="text-danger"
            >E-Mail Adresse ist erforderlich.</small
          >
          <small *ngIf="username?.errors?.['pattern']" class="text-danger"
            >Bitte geben Sie eine gültige E-Mail-Adresse an.</small
          >
          <!-- </div> -->
        </clr-control-error>
      </clr-input-container>

      <!-- Firstname -->
      <clr-input-container>
        <label for="firstname">Vorname</label>
        <input
          clrInput
          [class.is-invalid]="firstname?.invalid && firstname?.touched"
          type="text"
          id="firstname"
          name="firstname"
          placeholder=""
          formControlName="firstname"
        />
        <clr-control-error>
          <small *ngIf="firstname?.errors?.['required']" class="text-danger"
            >Vorname ist erforderlich.</small
          >
        </clr-control-error>
      </clr-input-container>

      <!-- Lastname -->
      <clr-input-container>
        <label for="lastname">Nachname</label>
        <input
          clrInput
          [class.is-invalid]="lastname?.invalid && lastname?.touched"
          type="text"
          id="lastname"
          name="lastname"
          placeholder=""
          formControlName="lastname"
        />
        <clr-control-error>
          <small *ngIf="lastname?.errors?.['required']" class="text-danger"
            >Nachname ist erforderlich.</small
          >
        </clr-control-error>
      </clr-input-container>

      <!-- Datenschutz -->

      <!-- <clr-checkbox-container>
        <label for="datenschutz">Datenschutz</label>
        <clr-checkbox-wrapper>
          <input
            type="checkbox"
            clrCheckbox
            formControlName="datenschutz"
            id="datenschutz"
          />
          <label for="datenschutz">Datenschutz</label>
        </clr-checkbox-wrapper>
        <clr-control-helper>Helper text</clr-control-helper>
        <clr-control-error>
          Datenschutz Zustimmung ist erforderlich.
        </clr-control-error>
      </clr-checkbox-container> -->

      <clr-toggle-container>
        <label for="datenschutz">Datenschutz</label>
        <clr-toggle-wrapper>
          <input
            type="checkbox"
            clrToggle
            formControlName="datenschutz"
            id="datenschutz"
          />
          <label for="datenschutz">Datenschutzerklärung zustimmen</label>
        </clr-toggle-wrapper>
        <clr-control-helper>
          <cds-icon shape="child-arrow"></cds-icon>
          <button
            type="button"
            (click)="onDatenschutzReadAndAgree()"
            class="btn btn-link btn-sm"
          >
            Datenschutzerklärung öffnen
          </button>
        </clr-control-helper>
        <clr-control-error>
          Datenschutz Zustimmung ist erforderlich.
        </clr-control-error>
      </clr-toggle-container>

      <!-- Password -->
      <clr-password-container>
        <label for="current-password">Passwort</label>
        <input
          clrPassword
          type="password"
          id="current-password"
          name="current-password"
          autocomplete="new-password"
          formControlName="password"
        />

        <clr-control-helper>
          Verwenden Sie mindestens 10 Zeichen aus 4 verschiedenen Zeichenarten,
          Groß- und Kleinschreibung, Zahlen und Sonderzeichen.
        </clr-control-helper>

        <clr-control-error>
          <small
            *ngIf="userEmails.get('password')?.hasError('required')"
            class="text-danger"
          >
            Passwort ist erforderlich!
          </small>
          <small
            *ngIf="userEmails.get('password')?.hasError('minlength')"
            class="text-danger"
          >
            Mindestlänge sind 10 Zeichen.
          </small>
          <small *ngIf="password?.errors?.['unmatching']" class="text-danger">
            Benutzername und Passwort dürfen nicht übereinstimmen.
          </small>
          <small *ngIf="password?.errors?.['complexity']" class="text-danger">
            Passwort muss aus mindestens vier verschiedenen Zeichenarten
            bestehen.
          </small>
        </clr-control-error>
      </clr-password-container>

      <clr-password-container>
        <label for="new-password">Passwort bestätigen</label>
        <input
          clrPassword
          type="password"
          id="new-password"
          name="new-password"
          autocomplete="new-password"
          formControlName="confirm"
        />
        <clr-control-error>
          <div *ngIf="userEmails.get('confirm')?.hasError('required')">
            Passwortbestätigung ist erforderlich!
          </div>
          <div *ngIf="userEmails.get('confirm')?.hasError('matching')">
            Passwortbestätigung stimmt nicht überein!
          </div>
        </clr-control-error>
      </clr-password-container>

      <!-- <div *ngIf="errorSignup" class="error active">
        {{ signupErrorResponse.name }}<br />
        [{{ signupErrorResponse.status }}] - [{{
          signupErrorResponse.statusText
        }}]
      </div> -->

      <clr-alert
        *ngIf="errorSignup"
        [clrAlertType]="'danger'"
        [clrAlertClosable]="false"
      >
        <clr-alert-item>
          <span class="alert-text">
            Ungültige Anmeldeinformationen.
            <br />
            <code
              >{{ signupErrorResponse.status }} |
              {{ signupErrorResponse.statusText }}
              <ng-container *ngIf="signupErrorResponse.error?.name">
                | {{ signupErrorResponse.error.name }} |
                {{ signupErrorResponse.error.message }}
              </ng-container>
            </code>
          </span>
        </clr-alert-item>
      </clr-alert>

      <button
        [clrLoading]="submitBtnState"
        type="submit"
        class="btn btn-primary"
        [disabled]="userEmails.invalid || !agrement"
      >
        ERSTELLEN
      </button>
    </div>
  </form>
</div>

<clr-modal
  [(clrModalOpen)]="multifactor"
  [clrModalClosable]="false"
  [clrModalStaticBackdrop]="false"
  [clrModalSize]="'md'"
>
  <h3 class="modal-title">
    <section class="title">
      <h3 class="welcome">
        <cds-icon shape="lock" size="lg"></cds-icon>
        Ihre Registrierung war erfolgreich!
      </h3>
    </section>
  </h3>

  <div class="modal-body">
    Wir haben eine E-Mail zur Verifizierung an die von Ihnen angegebene Adresse
    gesendet: <strong>{{ username?.value }}</strong
    >.
    <p>
      Bitte überprüfen Sie Ihr E-Mail-Postfach und klicken Sie auf den
      Verifizierungslink, um Ihr Konto zu aktivieren. Falls Sie die E-Mail nicht
      finden können, überprüfen Sie Ihren Spam-Ordner.
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="multifactor = false">
      schließen
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="datenschutz" [clrModalSize]="'xl'">
  <h3 class="modal-title">
    <section class="title">
      <h3 class="welcome">
        <cds-icon shape="gavel" size="lg"></cds-icon>
        Datenschutzhinweise Vrielmann Connect
      </h3>
    </section>
  </h3>

  <div class="modal-body">
    <p></p>
    <p cds-text="subsection">
      Indem du auf "Zustimmen" klickst, bestätigst du unsere Datenschutzhinweise
      und der Wiederrufsbelehrung zu.
    </p>
    <app-datenschutz></app-datenschutz>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onDatenschutzOkay()">
      zustimmen
    </button>
    <button
      type="button"
      class="btn btn-outline"
      (click)="onDatenschutzCancel()"
    >
      schließen
    </button>
  </div>
</clr-modal>
