import { Component, EventEmitter, Output, inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-dialog-add-image',
  templateUrl: './dialog-add-image.component.html',
  styleUrls: ['./dialog-add-image.component.css'],
})
export class DialogAddImageComponent {
  @Output() isDialogOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updated: EventEmitter<{ file: File; fileName: string }> =
    new EventEmitter<{ file: File; fileName: string }>();

  file: File | null = null;

  uploadProgress = 0;
  uploadSub$ = new Subscription();

  private formBuilder = inject(FormBuilder);

  formMobile = this.formBuilder.group({
    Image: this.formBuilder.group({
      Path: new FormControl('', { nonNullable: true }),
      Name: new FormControl('', { nonNullable: true }),
      id: new FormControl('', { nonNullable: true }),
      cid: new FormControl('', { nonNullable: true }),
    }),
  });

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  onSubmitFile() {
    const form = this.formMobile.getRawValue();
    if (this.file) {
      const resFile = {
        file: this.file,
        fileName: form.Image.Name,
      };
      this.updated.emit(resFile);
      this.closeDialog();
    }
  }

  closeDialog() {
    this.isDialogOpen.emit(false);
  }
}
