import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Cabinet } from 'src/app/models/Cabinet';
import { Evaluation } from 'src/app/models/Evaluation';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { Observable } from 'rxjs';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';

@Component({
  selector: 'app-risks-evaluations-overview',
  templateUrl: './risks-evaluations-overview.component.html',
  styleUrls: ['./risks-evaluations-overview.component.css'],
})
export class RiskEvaluationComponent implements OnInit, OnChanges {
  @Input() cabinet = undefined as unknown as Cabinet;
  evaluations$ = undefined as unknown as Observable<Evaluation[]>;
  selected = [] as Evaluation[];
  selectedEvaluation = undefined as unknown as Evaluation;
  extended = false;
  pdfShown = false;
  onHelper = false;
  onNewEvaluation = false;
  pdfBase64Src = null as any;
  title = '';
  message = '';
  isManager$ = this.store.select(selectProfilRoles(['admin']));
  online = this.service.isOnline$;

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    if (this.cabinet && this.cabinet?._id) {
      this.evaluations$ = this.service.loadEvaluationsByCabinet(
        this.cabinet._id,
      );
    }
  }

  ngOnInit() {
    if (this.cabinet && this.cabinet?._id) {
      this.evaluations$ = this.service.loadEvaluationsByCabinet(
        this.cabinet._id,
      );
    }
  }

  editEvaluationClick() {
    const id = this.selected[0]._id;
    this.router.navigate(['/risks/editevaluation', id], {
      relativeTo: this.activatedRoute,
    });
  }

  newEvaluationClick() {
    this.service.loadTemplateActual().subscribe((t) => {
      this.service
        .loadNewEvaluationsFor(this.cabinet._id, t._id)
        .subscribe((e) => {
          if (!e) {
            console.error('evaluation:', e);
            return;
          }
          this.router.navigate(['/risks/editevaluation', e._id], {
            relativeTo: this.activatedRoute,
          });
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cabinet']) {
      this.cabinet = changes['cabinet'].currentValue as Cabinet;
      if (this.cabinet && this.cabinet?._id) {
        this.evaluations$ = this.service.loadEvaluationsByCabinet(
          this.cabinet._id,
        );
        if (this.evaluations$) {
          this.evaluations$.subscribe((cabinetEvaluations) => {
            cabinetEvaluations?.forEach((e) => {
              if (e.PDFResult?.File) {
                this.service.getPdfEvaluation(e._id);
                // this.store.dispatch(
                //   FileActions.loadPdf({ evaluationId: e._id }),
                // );
              }
            });
          });
        }
      }
    }
  }

  disabledPdfCreateBtn(): boolean {
    if (this.selected.length === 1) {
      this.selectedEvaluation = this.selected[0];
      return this.selectedEvaluation?.PDFResult?.File != undefined;
    } else {
      this.selectedEvaluation = undefined as unknown as Evaluation;
    }
    return true;
  }

  onNewPdfResultClick() {
    this.pdfShown = false;
    this.extended = true;
    if (this.selectedEvaluation) {
      this.service
        .loadNewPdfFor(this.selectedEvaluation._id)
        .subscribe((result) => {
          this.pdfBase64Src = result?.data.data; //.toString('base64');
          this.pdfShown = true;
        });
      // this.store.dispatch(
      //   FileActions.loadNewPdfFor({
      //     evaluationId: this.selectedEvaluation._id,
      //   }),
      // );
      // const resultData$ = this.store.select(
      //   selectPdf(this.selectedEvaluation._id),
      // );
      // // .subscribe((result) => {
      // //   this.pdfBase64Src = result?.data; //.toString('base64');
      // //   this.pdfShown = true;
      // // });

      // resultData$.subscribe((result) => {
      //   this.pdfBase64Src = result?.data.data; //.toString('base64');
      //   this.pdfShown = true;
      // });
    }
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';
    this.onHelper = true;
    return false;
  }

  showPdf(evaluation: Evaluation) {
    this.pdfShown = false;
    this.extended = true;
    this.service.getPdfEvaluation(evaluation._id).subscribe((result) => {
      this.pdfBase64Src = result?.data.data; // .toString('base64');
      this.pdfShown = true;
    });
  }
}
