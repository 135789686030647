import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Contract } from 'src/app/models/Contract';
import { Station } from 'src/app/models/Station';
import { Document } from 'src/app/models/Document';

import * as CATEGORIES from 'src/app/constants/categories';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { Subject } from 'rxjs';

interface Item {
  Contract: Contract;
  Auftrag: Document[];
  Stations: Station[];
}

export class ColorFilter implements ClrDatagridFilterInterface<Item> {
  public selectedColors: string[] = [];

  public changes = new Subject<any>();

  public isActive(): boolean {
    return this.selectedColors.length > 0;
  }

  public accepts(user: Item): boolean {
    return this.selectedColors.indexOf(user.Contract.Type) > -1;
  }
}

@Component({
  selector: 'app-contracts-list-grid',
  templateUrl: './contracts-list-grid.component.html',
  styleUrls: ['./contracts-list-grid.component.css'],
})
export class ContractsListGridComponent implements OnInit, OnChanges {
  customColorFilter: ColorFilter = new ColorFilter();

  @Input() contracts = [] as Contract[];
  @Input() loading = false;

  @Input() extended = true;
  @Input() hiddenName = false;
  @Input() hiddenType = false;

  Items = [] as Item[];

  AUFTRAG = CATEGORIES.AUFTRAG;

  ngOnChanges(changes: SimpleChanges): void {
    this.updateItems();
  }

  ngOnInit(): void {
    this.updateItems();
  }

  updateItems() {
    this.Items = this.contracts?.map((contract) => ({
      Contract: contract,
      Auftrag: contract.Documents.filter(
        (document) =>
          document.Categories?.some(
            (category) => category.Uid === CATEGORIES.AUFTRAG,
          ),
      ),
      Stations: contract.Stations,
    }));
  }

  toggleSelection(event: any) {
    if (event.target.checked) {
      this.customColorFilter.selectedColors.push(event.target.value);
    } else {
      const colorName = event.target.value;
      const index = this.customColorFilter.selectedColors.indexOf(colorName);
      if (index > -1) {
        this.customColorFilter.selectedColors.splice(index, 1);
      }
    }
    this.customColorFilter.changes.next(true);
  }
}
