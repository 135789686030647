import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Cabinet, CabinetImg } from 'src/app/models/Cabinet';
import { ClrLoadingState } from '@clr/angular';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-risks-tab-images-cabinet',
  templateUrl: './risks-tab-images-cabinet.component.html',
  styleUrls: ['./risks-tab-images-cabinet.component.css'],
})
export class RiskTabCabinetImagesComponent implements OnInit {
  @Input() cabinet: Cabinet | null = null;
  @Output() updated = new EventEmitter();
  @Output() init = new EventEmitter();
  online = this.service.isOnline$;

  dialogAddFile = false;
  title = '';
  message = '';
  imgViewSrc = '';
  dialogViewImg = false;

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private service: AssessmentStoreService) {}

  ngOnInit() {
    this.init.emit(this);
  }

  onDialogAddFileClosing() {
    this.dialogAddFile = false;
  }

  onAddFileClick() {
    this.dialogAddFile = true;
    return false;
  }

  getThumbImage(image: CabinetImg) {
    const str64 = String.fromCharCode.apply(null, image.Thumb.data);
    return `data:${image.MIME};base64,${btoa(str64)}`;
  }

  showImage(image: CabinetImg) {
    if (this.cabinet) {
      this.service
        .loadCabinetImage(this.cabinet._id, image._id)
        .subscribe((result) => {
          this.imgViewSrc = `data:${image.MIME};base64,${result?.data}`;
          this.dialogViewImg = true;
        });
    }
  }

  onSetPreviewImg(image: CabinetImg) {
    this.submitTabState = ClrLoadingState.LOADING;
    if (this.cabinet) {
      const images = [] as CabinetImg[];
      this.cabinet?.Images?.map((img) => {
        if (img._id == image._id) {
          img.AsPreview = true;
        } else {
          img.AsPreview = false;
        }
        images.push(img);
      });
      const patchCabinet = {
        _id: this.cabinet._id,
        Images: images,
      } as Cabinet;
      this.service.patchCabinet(patchCabinet).subscribe((cabinet) => {
        this.cabinet = cabinet;
        this.submitTabState = ClrLoadingState.SUCCESS;
      });
    } else {
      this.submitTabState = ClrLoadingState.SUCCESS;
    }
  }

  onUpdateFile(fileData: any) {
    console.log('onUpdateFile()', fileData);

    const fileDat = fileData as {
      file: File;
      fileName: string;
    };
    if (fileDat.file && this.cabinet) {
      this.service
        .postCabinetImage(fileDat.file, fileDat.fileName, this.cabinet)
        .subscribe((result) => {
          this.cabinet = result as Cabinet;
          this.updated.emit(this.cabinet);
          this.ngOnInit();
        });
    }
  }

  onDeleteFile(control: any) {
    this.submitTabState = ClrLoadingState.LOADING;
    if (this.cabinet) {
      console.log(`onDeleteFile control?._id : ${control?._id}`);
      this.service
        .deleteCabinetImage(this.cabinet._id, control?._id)
        .subscribe((C) => {
          this.cabinet = C as Cabinet;
          this.updated.emit(C as Cabinet);
          this.submitTabState = ClrLoadingState.SUCCESS;
        });
    } else {
      this.submitTabState = ClrLoadingState.SUCCESS;
    }
  }
}
