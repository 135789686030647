<!-- <form clrForm [formGroup]="formDynamisch" (ngSubmit)="onSubmitTab()"> -->
<ng-container *ngIf="itmArray$">
  <!-- --------- Item-Liste ---------- -->
  <clr-datagrid #templategrid>
    <!-- <clr-datagrid #templategrid [(clrDgSelected)]="tempselection"> -->
    <clr-dg-placeholder>
      Wir konnten keine Einträge finden!
    </clr-dg-placeholder>
    <!-- Columns -->
    <!--
      // _id?: string;
      // Title: string;
      // Fixed?: number;
      // IsPrimary: boolean;
      // ItemIndex: number;
      // ItemType?: 'Boolean' | 'Number' | 'Date' | 'String' | 'Strings';
      // Info?: string;
      // Selection?: string[]; // Auswahlmöglichkeiten bei Auswahlfeldern
      // Value?: any; -->
    <!-- <clr-dg-column
        [clrDgField]="'_id'"
        *clrDgHideableColumn="{ hidden: true }"
        >_id</clr-dg-column
      > -->
    <clr-dg-column [clrDgField]="'ItemIndex'">Index</clr-dg-column>
    <clr-dg-column [clrDgField]="'IsPrimary'"
      >Spaltensichtbarkeit</clr-dg-column
    >
    <clr-dg-column [clrDgField]="'Title'">Titel</clr-dg-column>
    <clr-dg-column [clrDgField]="'ItemType'">Type</clr-dg-column>
    <clr-dg-column [clrDgField]="'Info'">Info</clr-dg-column>
    <clr-dg-column>Aktionen</clr-dg-column>
    <!-- <clr-dg-column>Index</clr-dg-column>
      <clr-dg-column>Spaltensichtbarkeit</clr-dg-column>
      <clr-dg-column>Titel</clr-dg-column>
      <clr-dg-column>Type</clr-dg-column>
      <clr-dg-column>Info</clr-dg-column>
      <clr-dg-column>Aktionen</clr-dg-column> -->
    <!-- Rows -->
    <clr-dg-row *clrDgItems="let itm of itmArray$" [clrDgItem]="itm">
      <clr-dg-cell
        id="indexCell"
        (click)="itemToChangeClicked('index', itm)"
        [ariaDisabled]="isDisabled(itm)"
        style="cursor: pointer"
      >
        Index: {{ itm.ItemIndex + 1 }}
      </clr-dg-cell>
      <clr-dg-cell id="primaryCell">
        <clr-toggle-container class="left" id="addPrimary">
          <clr-toggle-wrapper>
            <input
              class="left"
              type="checkbox"
              clrToggle
              [value]="itm.IsPrimary"
              [checked]="itm.IsPrimary"
            />
          </clr-toggle-wrapper>
        </clr-toggle-container>
      </clr-dg-cell>
      <clr-dg-cell
        id="titelCell"
        (click)="itemToChangeClicked('titel', itm)"
        [style]="getStyle(itm)"
      >
        {{ itm.Title }}
      </clr-dg-cell>
      <clr-dg-cell
        id="typeCell"
        (click)="itemToChangeClicked('type', itm)"
        [style]="getStyle(itm)"
      >
        {{ itm.ItemType }}
      </clr-dg-cell>
      <clr-dg-cell
        id="infoCell"
        (click)="itemToChangeClicked('info', itm)"
        [style]="getStyle(itm)"
      >
        {{ itm.Info }}
      </clr-dg-cell>
      <clr-dg-cell id="actionCell">
        <ng-container *ngIf="!isDisabled(itm)">
          <button
            class="btn btn-sm"
            [clrLoading]="onEditSubmitState"
            (click)="onDeleteClick(itm._id)"
            [disabled]="isDisabled(itm)"
          >
            <cds-icon shape="trash" />
          </button>
        </ng-container>
      </clr-dg-cell>
      <!-- <clr-dg-cell id="actionCell">
        <button
          type="submit"
          class="btn btn-primary"
          [clrLoading]="onEditSubmitState"
          (click)="onSaveClick(itm.ItemIndex)"
          [disabled]="itm.Fixed === 3"
        >
          speichern
        </button>
      </clr-dg-cell> -->
    </clr-dg-row>
  </clr-datagrid>
  <!------------ Als Tabelle (mit Form) ------------>
  <!-- <table class="table">
      <thead>
        <tr>
          <th class="left">Index</th>
          <th class="left">Spaltensichtbarkeit</th>
          <th class="left">Titel</th>
          <th class="left">Type</th>
          <th class="left">Info</th>
          <th class="left">Aktionen</th>
        </tr>
      </thead>
      <tbody>
        <tr
          clrFormGroup
          *ngFor="let itm of itmArray$; let i = index"
          [formGroupName]="itm._id"
          (ngModelChange)="inputChanged()"
          (ngSubmit)="onEditFieldSubmit()"
        >
          <td class="left" id="colIndex">
            <clr-input-container class="left" id="addIndex">
              <input
                class="left"
                style="width: 100%"
                type="number"
                clrInput
                formControlName="ItemIndex"
              />
            </clr-input-container>
          </td>
          <td class="left" id="colSpSicht">
            <input style="visibility: hidden" clrInput formControlName="_id" />
            <clr-toggle-container class="left" id="addPrimary">
              <clr-toggle-wrapper>
                <input
                  class="left"
                  type="checkbox"
                  clrToggle
                  [formControlName]="'IsPrimary'"
                />
              </clr-toggle-wrapper>
            </clr-toggle-container>
          </td>
          <td class="left" id="colTitel">
            <clr-input-container class="left" id="addTitle">
              <input
                class="left"
                style="width: 100%"
                clrInput
                formControlName="Title"
              />
            </clr-input-container>
          </td>
          <td class="left" id="colType">
            <clr-select-container class="left" id="addType">
              <select
                class="left"
                clrSelect
                style="width: 100%"
                formControlName="ItemType"
                (change)="onFieldTypeChanged($event, itm._id)"
              >
                <option style="width: 100%" value="Strings">Auswahl</option>
                <option style="width: 100%" value="String">Eingabe</option>
                <option style="width: 100%" value="Date">Datum</option>
                <option style="width: 100%" value="Number">Nummerisch</option>
                <option style="width: 100%" value="Bolean">Ja/Nein</option>
              </select>
            </clr-select-container>
            <ng-container *ngIf="isItmAuswahl(itm._id)" id="inputContainer">
              <div class="clr-form-control clr-row" id="inputContainerRow">
                <div class="clr-col-12 clr-col-md-2">
                  <label
                    for="inputContainer"
                    style="color: #6e8ba3"
                    class="clr-control-label clr-col-12 clr-col-md-2"
                  >
                    Elemente
                  </label>
                </div>
                <div class="grid-container clr-col-md-10 clr-col-12"> -->
  <!-- <ng-container
                      style="margin-left: 50px; direction: vertical"
                      id="Auswahl"
                      *ngFor="let ctlOption of tempAuswahlElements; index as i"
                    >
                      <div class="grid-item-col1">
                        <label for="Auswahl"> {{ ctlOption }}</label>
                        <button
                          class="btn btn-sm btn-link"
                          (click)="deleteAuswahlByIndex($event, i)"
                          title="entfernen"
                        >
                          <cds-icon shape="eraser"></cds-icon>
                        </button>
                      </div>
                    </ng-container> -->
  <!-- </div>
              </div>
              <clr-input-container id="addElement">
                <label for="addElement">
                  <cds-icon shape="plus-circle" /> Auswahl Element
                </label>
                <input
                  style="width: 100%"
                  clrInput
                  formControlName="Addfield"
                  (keydown)="onAddAuswahlElementClick($event)"
                />
              </clr-input-container>
            </ng-container>
          </td>
          <td class="left" id="colInfo">
            <clr-textarea-container class="left" id="addInfo">
              <textarea
                class="left"
                style="width: 100%"
                clrTextarea
                formControlName="Info"
                >{{ itm.Info }}</textarea
              >
            </clr-textarea-container>
          </td>
          <td class="left" id="colAktion">
            <button
              type="submit"
              class="btn btn-primary"
              [clrLoading]="onEditSubmitState"
              (click)="onSaveClick(itm.ItemIndex)"
              [disabled]="itm.Fixed === 3"
            >
              speichern
            </button>
          </td>
        </tr>
      </tbody>
    </table> -->
  <!-- <br />
  <button
    type="submit"
    class="btn btn-primary"
    [clrLoading]="onEditSubmitState"
    (click)="onSaveClick()"
  >
    speichern
  </button> -->
</ng-container>
<!-- <p></p> -->
<!-- <button class="btn btn-outline" (click)="onAddFieldClick()">
    <cds-icon shape="plus-circle"></cds-icon> feld hinzufügen
  </button>
  <button
    type="submit"
    class="btn btn-primary"
    [clrLoading]="submitTabState"
    style="margin-left: 10px"
  >
    aktualisieren
  </button> -->
<!-- </form> -->

<!-- popup-windows -->

<clr-modal [(clrModalOpen)]="onMoving">
  <h3 class="modal-title" *ngIf="itemToChange">
    <cds-icon shape="tag"></cds-icon>
    Original-Index: {{ itemToChange.ItemIndex + 1 }}
  </h3>
  <div class="modal-body" *ngIf="itemToChange">
    <span style="float: left" style="display: flex; flex-direction: row">
      <div style="display: flex; flex-direction: column">
        <cds-icon
          shape="angle"
          direction="up"
          size="xs"
          (click)="moveUp()"
          class="btn btn-primary"
        />
        <br />
        <cds-icon
          shape="angle"
          direction="down"
          size="xs"
          (click)="moveDown()"
          class="btn btn-primary"
        />
      </div>
      <p style="margin-left: 10px">neuer Index: {{ movingIndex + 1 }}</p>
    </span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveMovingIndex()">
      Ok
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onType">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    Type
  </h3>
  <div class="modal-body">
    <clr-select-container class="left" id="addType" *ngIf="itemToChange">
      <select
        class="left"
        clrSelect
        style="width: 100%"
        [value]="itemToChange.ItemType"
        (change)="onFieldTypeChanged($event, itemToChange)"
      >
        <option style="width: 100%" value="Strings">Auswahl</option>
        <option style="width: 100%" value="String">Eingabe</option>
        <option style="width: 100%" value="Date">Datum</option>
        <option style="width: 100%" value="Number">Nummerisch</option>
        <option style="width: 100%" value="Bolean">Ja/Nein</option>
      </select>
    </clr-select-container>
    <ng-container
      *ngIf="itemToChange?.ItemType === 'Strings'"
      id="inputContainer"
    >
      <button>Auswahl bearbeiten</button>
    </ng-container>
    <!-- <ng-container *ngIf="isItmAuswahl(itm._id)" id="inputContainer">
      <div class="clr-form-control clr-row" id="inputContainerRow">
        <div class="clr-col-12 clr-col-md-2">
          <label
            for="inputContainer"
            style="color: #6e8ba3"
            class="clr-control-label clr-col-12 clr-col-md-2"
          >
            Elemente
          </label>
        </div>
        <div class="grid-container clr-col-md-10 clr-col-12">
          <ng-container
                style="margin-left: 50px; direction: vertical"
                id="Auswahl"
                *ngFor="let ctlOption of tempAuswahlElements; index as i"
              >
                <div class="grid-item-col1">
                  <label for="Auswahl"> {{ ctlOption }}</label>
                  <button
                    class="btn btn-sm btn-link"
                    (click)="deleteAuswahlByIndex($event, i)"
                    title="entfernen"
                  >
                    <cds-icon shape="eraser"></cds-icon>
                  </button>
                </div>
              </ng-container>
        </div>
      </div>
      <clr-input-container id="addElement">
        <label for="addElement">
          <cds-icon shape="plus-circle" /> Auswahl Element
        </label>
        <input
          style="width: 100%"
          clrInput
          formControlName="Addfield"
          (keydown)="onAddAuswahlElementClick($event)"
        />
      </clr-input-container>
    </ng-container> -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveType()">
      Ok
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onTitel">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    Titel
  </h3>
  <div class="modal-body" *ngIf="itemToChange">
    <span style="float: left; display: flex; flex-direction: row">
      <p>Titel:</p>
      <!-- <clr-input-container class="left" id="addTitle">
        <label for="addTitle">Title: </label> -->
      <input
        #titelInput
        class="left"
        style="width: 100%; margin-left: 10px"
        clrInput
        [value]="itemToChange.Title"
      />
      <!-- </clr-input-container> -->
    </span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveTitel()">
      Ok
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onInfo">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    Infotext
  </h3>
  <div class="modal-body" *ngIf="itemToChange">
    <textarea
      #infotxtarea
      class="center"
      cols="60"
      rows="6"
      style="
        min-width: 250px;
        max-width: 100%;
        min-height: 50px;
        height: 100%;
        width: 100%;
      "
      clrTextarea
      [value]="itemToChange.Info"
    ></textarea>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveInfo()">
      Ok
    </button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onAdd">
  <h3 class="modal-title">
    <cds-icon shape="plus-circle"></cds-icon> hinzufügen
  </h3>
  <div class="modal-body">
    <!-- <form clrForm [formGroup]="formItem" (ngSubmit)="onAddFieldSubmit()">
      <clr-toggle-container id="addPrimary">
        <label for="addPrimary">Primary</label>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle formControlName="IsPrimary" />
        </clr-toggle-wrapper>
      </clr-toggle-container>

      <clr-input-container id="addTitle">
        <label for="addTitle">Titel</label>
        <input style="width: 100%" clrInput formControlName="Title" />
      </clr-input-container>

      <clr-select-container id="addType">
        <label for="addType">Feld-Art</label>
        <select
          clrSelect
          style="width: 100%"
          formControlName="Type"
          (change)="onFieldTypeChanged($event)"
        >
          <option style="width: 100%" value="Strings">Auswahl</option>
          <option style="width: 100%" value="String">Eingabe</option>
          <option style="width: 100%" value="Date">Datum</option>
          <option style="width: 100%" value="Number">Nummerisch</option>
          <option style="width: 100%" value="Bolean">Ja/Nein</option>
        </select>
      </clr-select-container>

      <ng-container *ngIf="showAuswahl" id="inputContainer">
        <div class="clr-form-control clr-row" id="inputContainerRow">
          <div class="clr-col-12 clr-col-md-2">
            <label
              for="inputContainer"
              style="color: #6e8ba3"
              class="clr-control-label clr-col-12 clr-col-md-2"
            >
              Elemente
            </label>
          </div>
          <div class="grid-container clr-col-md-10 clr-col-12">
            <ng-container
              style="margin-left: 50px; direction: vertical"
              id="Auswahl"
              *ngFor="let ctlOption of tempAuswahlElements; index as i"
            >
              <div class="grid-item-col1">
                <label for="Auswahl"> {{ ctlOption }}</label>
                <button
                  class="btn btn-sm btn-link"
                  (click)="deleteAuswahlByIndex($event, i)"
                  title="entfernen"
                >
                  <cds-icon shape="eraser"></cds-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <clr-input-container id="addElement">
          <label for="addElement">
            <cds-icon shape="plus-circle" /> Auswahl Element
          </label>
          <input
            style="width: 100%"
            clrInput
            formControlName="Addfield"
            (keydown)="onAddAuswahlElementClick($event)"
          />
        </clr-input-container>
      </ng-container>

      <clr-input-container id="addIndex">
        <label for="addIndex">Index</label>
        <input
          style="width: 100%"
          type="number"
          clrInput
          formControlName="ItemIndex"
        />
      </clr-input-container>

      <clr-textarea-container id="addInfo">
        <label for="addInfo">Info</label>
        <textarea
          style="width: 100%"
          clrTextarea
          formControlName="Info"
        ></textarea>
      </clr-textarea-container>

      <clr-input-container id="addValue">
        <label for="addValue">Wert</label>
        <input style="width: 100%" clrInput formControlName="Value" />
      </clr-input-container>

      <p>&nbsp;</p>
      <button
        type="submit"
        class="btn btn-primary"
        [clrLoading]="onAddSubmitState"
      >
        hinzufügen
      </button>
    </form> -->
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onDelete">
  <h3 class="modal-title"><cds-icon shape="eraser"></cds-icon> entfernen</h3>
  <div class="modal-body">
    <!-- <form clrForm [formGroup]="formItem" (ngSubmit)="onDeleteSubmit()">
      <clr-toggle-container id="delPrimaryContainer">
        <label for="delPrimaryContainer">Primary</label>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle formControlName="IsPrimary" />
        </clr-toggle-wrapper>
      </clr-toggle-container>

      <clr-input-container id="delTitleContainer">
        <label for="delTitleContainer">Title</label>
        <input style="width: 100%" clrInput formControlName="Title" />
      </clr-input-container>

      <clr-input-container id="delIndexContainer">
        <label for="delIndexContainer">Index</label>
        <input
          style="width: 100%"
          type="number"
          clrInput
          formControlName="ItemIndex"
        />
      </clr-input-container>

      <clr-textarea-container id="delInfoContainer">
        <label for="delInfoContainer">Info</label>
        <textarea
          style="width: 100%"
          clrTextarea
          formControlName="Info"
        ></textarea>
      </clr-textarea-container>

      <p>&nbsp;</p>
      <button
        type="submit"
        class="btn btn-primary"
        [clrLoading]="onDeleteSubmitState"
      >
        entfernen
      </button>
    </form> -->
  </div>
  <div class="modal-footer"></div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onEdit">
  <h3 class="modal-title">Bearbeiten</h3>
  <div class="modal-body">
    <!-- <form clrForm [formGroup]="formItem" (ngSubmit)="onEditFieldSubmit()">
      <clr-toggle-container id="addPrimary">
        <label for="addPrimary">Primary</label>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle formControlName="IsPrimary" />
        </clr-toggle-wrapper>
      </clr-toggle-container>

      <clr-input-container id="addTitle">
        <label for="addTitle">Titel</label>
        <input style="width: 100%" clrInput formControlName="Title" />
      </clr-input-container>

      <clr-select-container id="addType">
        <label for="addType">Feld-Art</label>
        <select
          clrSelect
          style="width: 100%"
          formControlName="Type"
          (change)="onFieldTypeChanged($event)"
        >
          <option style="width: 100%" value="Strings">Auswahl</option>
          <option style="width: 100%" value="String">Eingabe</option>
          <option style="width: 100%" value="Date">Datum</option>
          <option style="width: 100%" value="Number">Nummerisch</option>
          <option style="width: 100%" value="Bolean">Ja/Nein</option>
        </select>
      </clr-select-container>

      <ng-container *ngIf="showAuswahl" id="inputContainer">
        <div class="clr-form-control clr-row" id="inputContainerRow">
          <div class="clr-col-12 clr-col-md-2">
            <label
              for="inputContainer"
              style="color: #6e8ba3"
              class="clr-control-label clr-col-12 clr-col-md-2"
            >
              Elemente
            </label>
          </div>
          <div class="grid-container clr-col-md-10 clr-col-12">
            <ng-container
              style="margin-left: 50px; direction: vertical"
              id="Auswahl"
              *ngFor="let ctlOption of tempAuswahlElements; index as i"
            >
              <div class="grid-item-col1">
                <label for="Auswahl"> {{ ctlOption }}</label>
                <button
                  class="btn btn-sm btn-link"
                  (click)="deleteAuswahlByIndex($event, i)"
                  title="entfernen"
                >
                  <cds-icon shape="eraser"></cds-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <clr-input-container id="addElement">
          <label for="addElement">
            <cds-icon shape="plus-circle" /> Auswahl Element
          </label>
          <input
            style="width: 100%"
            clrInput
            formControlName="Addfield"
            (keydown)="onAddAuswahlElementClick($event)"
          />
        </clr-input-container>
      </ng-container>

      <clr-input-container id="addIndex">
        <label for="addIndex">Index</label>
        <input
          style="width: 100%"
          type="number"
          clrInput
          formControlName="ItemIndex"
        />
      </clr-input-container>

      <clr-textarea-container id="addInfo">
        <label for="addInfo">Info</label>
        <textarea
          style="width: 100%"
          clrTextarea
          formControlName="Info"
        ></textarea>
      </clr-textarea-container>

      <input style="visibility: hidden" clrInput formControlName="_id" />
      <clr-input-container id="addValue">
        <label for="addValue">Wert</label>
        <input style="width: 100%" clrInput formControlName="Value" />
      </clr-input-container>

      <p>&nbsp;</p>
      <button
        type="submit"
        class="btn btn-primary"
        [clrLoading]="onEditSubmitState"
      >
        speichern
      </button>
    </form> -->
  </div>
</clr-modal>
<!-- <clr-modal [(clrModalOpen)]="onHelper">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onHelper = false">
      Ok
    </button>
  </div>
</clr-modal> -->
