import {
  Component,
  Input,
  OnInit,
  inject,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Cabinet } from 'src/app/models/Cabinet';
import { formatDate } from '@angular/common';

import { ClrLoadingState } from '@clr/angular';
import { Item } from 'src/app/models/Item';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { Template } from 'src/app/models/Template';

@Component({
  selector: 'app-risks-tab-cabinet',
  templateUrl: './risks-tab-cabinet.component.html',
  styleUrls: ['./risks-tab-cabinet.component.css'],
})
export class RiskTabCabinetComponent
  implements OnInit, AfterViewInit, OnChanges
{
  tempAuswahlElements = [] as string[];
  @Input() cabinet: Cabinet | null = null;
  @Output() updated = new EventEmitter();
  @Output() init = new EventEmitter();
  private template: Template | null = null;
  title = '';
  message = '';
  showAuswahl = false;
  private formBuilder = inject(FormBuilder);

  formDynamisch = this.formBuilder.group({});

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;
  onHelper = false;

  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['manager']));

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
  ) {}

  getItems(): Item[] {
    if (this.template) {
      const sorted = this.template.Anlage.sort(
        (a, b) => a.ItemIndex - b.ItemIndex,
      );
      return sorted ? sorted : ([] as Item[]);
    }
    return [] as Item[];
  }

  inputChanged() {
    // const formData = this.formDynamisch.getRawValue();
    // const arrayItem = Object.entries(formData).map(
    //   ([Title, Value], index) =>
    //     ({
    //       ItemId: this.templateItemArray$.find((x) => x.ItemIndex == index)
    //         ?._id,
    //       Value: Value,
    //     }) as { ItemId: string; Value: object },
    // );
    // const cabinet: Cabinet = {
    //   _id: this.cabinet._id,
    // } as Cabinet;
    // cabinet.Anlage = arrayItem;
    // this.changed.emit(cabinet);
  }

  ngOnInit() {
    if (this.cabinet) {
      this.refreshView();
    }
    this.init.emit(this);
  }

  ngAfterViewInit() {
    if (this.cabinet) {
      this.refreshView();
    }
  }

  refreshView() {
    const dynForms = this.formDynamisch.getRawValue();
    if (!(Object.entries(dynForms).length > 0)) {
      if (this.template) {
        try {
          this.template.Anlage.map((templateItem) => {
            if (!this.formDynamisch.get<string>(templateItem._id)) {
              const control = this.formBuilder?.control(templateItem.Title);
              this.formDynamisch?.addControl(templateItem._id, control);
              const cabinetItem = this.cabinet?.Anlage.find(
                (a) => a.ItemId == templateItem._id,
              );
              this.setControlValue(control, cabinetItem);
            }
          });
        } catch (err) {
          console.error('FEHLER : ', err);
        }
      } else if (this.cabinet) {
        let templateId: string | null = null;
        if (typeof this.cabinet.Template === 'string') {
          templateId = this.cabinet?.Template?.trim() as string;
        } else {
          templateId = (this.cabinet.Template as Template)._id;
        }
        this.service.loadTemplate(templateId).subscribe((template) => {
          if (template) {
            this.template = template;
            try {
              this.template.Anlage.map((templateItem) => {
                let control = this.formDynamisch.get<string>(templateItem._id);
                if (!control) {
                  control = this.formBuilder?.control(templateItem.Title);
                  this.formDynamisch?.addControl(templateItem._id, control);
                }
                const cabinetItem = this.cabinet?.Anlage.find(
                  (a) => a.ItemId == templateItem._id,
                );
                this.setControlValue(control, cabinetItem);
              });
            } catch (err) {
              console.error('FEHLER : ', err);
            }
          }
        });
      }
    } else {
      this.cabinet?.Anlage.map((cabinetItm) => {
        const control = this.formDynamisch.get(cabinetItm.ItemId);
        this.setControlValue(control, cabinetItm);
      });
    }
  }

  setControlValue(
    control: AbstractControl<any, any> | null | undefined,
    cabinetItem: { ItemId: string; Value: object } | undefined,
  ) {
    if (
      control &&
      cabinetItem &&
      cabinetItem.Value !== undefined &&
      cabinetItem.Value !== null
    ) {
      try {
        const tmpItem = this.template?.Anlage.find(
          (tmpItm) => tmpItm._id == cabinetItem.ItemId,
        );
        if (tmpItem && tmpItem.ItemType == 'Date') {
          try {
            const date = new Date(cabinetItem.Value as unknown as string);
            const val = formatDate(date, 'yyyy-MM-dd', 'de-DE');
            control.setValue(val);
          } catch (err) {
            console.error(err);
          }
        } else if (tmpItem && tmpItem.ItemType == 'Strings') {
          const value = cabinetItem.Value as unknown as string;
          if (
            tmpItem.Selection &&
            tmpItem.Selection.length > 0 &&
            tmpItem.Selection.includes(value)
          ) {
            control.setValue(cabinetItem.Value);
          } else if (tmpItem.Selection) {
            control.setValue(tmpItem.Selection[0]);
          }
        } else {
          control.setValue(cabinetItem.Value);
        }
      } catch (err) {
        console.error(err);
        return;
      }
    } else if (
      cabinetItem &&
      (cabinetItem.Value == undefined || cabinetItem.Value == null)
    ) {
      console.warn(
        `refreshView() Item[${cabinetItem.ItemId}] Value`,
        cabinetItem.Value,
      );
    }
  }

  ngOnChanges(changes: any): void {
    try {
      if (changes['cabinet']) {
        this.refreshView();
      }
    } catch (err) {
      console.error('FEHLER : ', err);
    }
  }

  getStyle(itm: Item): string {
    const templateItem = itm;
    const formControl = this.formDynamisch.get<string>(itm._id) as FormControl;
    if (formControl !== undefined && formControl !== null) {
      const Value = formControl.getRawValue();
      if (templateItem?.Fixed && templateItem?.Fixed > 0) {
        switch (templateItem.ItemType) {
          case 'Boolean':
            if (Value && !(typeof Value == typeof Boolean)) {
              return 'color: red';
            } else if (Value) {
              return 'color: red';
            }
            break;
          case 'Date':
            try {
              const date = new Date(Value as unknown as string);
              formatDate(date, 'yyyy-MM-dd', 'de-DE');
            } catch (err) {
              return 'color: red';
            }
            break;
          case 'Number':
            if (isNaN(Value as number)) {
              return 'color: red';
            }
            break;
          case 'String':
            break;
          case 'Strings':
            try {
              if (!templateItem.Selection?.includes(Value as string)) {
                return 'color: red';
              }
            } catch (err) {
              return 'color: red';
            }
            break;
        }
      }
    }
    return '';
  }

  onSubmitTab(event: any) {
    this.submitTabState = ClrLoadingState.LOADING;
    let entriesOk = true;
    const tab = this.formDynamisch.getRawValue();
    const arrayItem = Object.entries(tab).map(([ItemId, Value], index) => {
      const templateItem = this.template?.Anlage.find(
        (x) => x.ItemIndex == index,
      );
      if (templateItem?.Fixed && templateItem?.Fixed > 0 && entriesOk) {
        switch (templateItem.ItemType) {
          case 'Boolean':
            if ((Value && !(typeof Value == typeof Boolean)) || !Value) {
              entriesOk = false;
              return undefined as unknown as { ItemId: string; Value: object };
            }
            break;
          case 'Date':
            try {
              const date = new Date(Value as unknown as string);
              const val = formatDate(date, 'yyyy-MM-dd', 'de-DE');
              Value = val;
            } catch (err) {
              console.error('onSubmitTab() : KEIN DATUM', err);
              entriesOk = false;
              return undefined as unknown as { ItemId: string; Value: object };
            }
            break;
          case 'Number':
            if (isNaN(Value as number)) {
              console.error('onSubmitTab() :KEINE ZAHL');
              entriesOk = false;
              return undefined as unknown as {
                ItemId: string;
                Value: object;
              };
            }
            break;
          case 'String':
            break;
          case 'Strings':
            try {
              if (!templateItem.Selection?.includes(Value as string)) {
                console.error('onSubmitTab() :Auswahl nicht enthalten !!!!');
                entriesOk = false;
                return undefined as unknown as {
                  ItemId: string;
                  Value: object;
                };
              }
            } catch (err) {
              console.error(
                'onSubmitTab() :Auswahl hat Fehler verursacht',
                err,
              );
              entriesOk = false;
              return undefined as unknown as { ItemId: string; Value: object };
            }
            break;
        }
      }
      return {
        ItemId: templateItem?._id,
        Value: Value,
      } as { ItemId: string; Value: object };
    });
    if (!entriesOk) {
      console.warn('onSubmitTab() : Inputs not correct !!!');
      this.submitTabState = ClrLoadingState.SUCCESS;
      return;
    }
    if (this.cabinet && this.cabinet._id !== null) {
      const cabinet: Cabinet = {
        _id: this.cabinet._id,
      } as Cabinet;
      cabinet.Anlage = arrayItem;
      this.service.patchCabinet(cabinet).subscribe((cabinet) => {
        if (cabinet) {
          this.cabinet = cabinet;
          this.updated.emit(cabinet);
        }
        this.submitTabState = ClrLoadingState.SUCCESS;
      });
    }
    // this.submitTabState = ClrLoadingState.SUCCESS;
  }

  deleteAuswahlByIndex(event: any, index: number) {
    this.tempAuswahlElements.splice(index, 1);
    event.preventDefault();
    return false;
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';
    this.onHelper = true;
    // event.preventDefault();
    return false;
  }
}
