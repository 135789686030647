<clr-datagrid [clrDgLoading]="loading">
  <clr-dg-placeholder>Wir konnten keine Einträge finden!</clr-dg-placeholder>

  <clr-dg-column [clrDgField]="'Contract.Nr'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      <cds-icon shape="hashtag"></cds-icon>&nbsp;Nr
    </ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'Contract.Name'">
    <ng-container *clrDgHideableColumn="{ hidden: hiddenName }">
      <cds-icon shape="tag"></cds-icon>&nbsp;Name
    </ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'Contract.Type'">
    <ng-container *clrDgHideableColumn="{ hidden: hiddenType }">
      <cds-icon shape="tag"></cds-icon>&nbsp;Typ
    </ng-container>
    <clr-dg-filter [clrDgFilter]="customColorFilter">
      <clr-checkbox-wrapper>
        <input
          type="checkbox"
          clrCheckbox
          value="Wartungsvertrag"
          name="options"
          (change)="toggleSelection($event)"
        />
        <label>Wartungsvertrag</label>
      </clr-checkbox-wrapper>
      <clr-checkbox-wrapper>
        <input
          type="checkbox"
          clrCheckbox
          value="Wartungsprojekt"
          name="options"
          (change)="toggleSelection($event)"
        />
        <label>Wartungsprojekt</label>
      </clr-checkbox-wrapper>
    </clr-dg-filter>
  </clr-dg-column>

  <clr-dg-row *clrDgItems="let item of Items" [clrDgItem]="item">
    <clr-dg-cell>
      <a [routerLink]="['/contracts', item.Contract.Uid]">
        {{ item.Contract.Nr }}
      </a>
    </clr-dg-cell>
    <clr-dg-cell>
      <a [routerLink]="['/contracts', item.Contract.Uid]">
        {{ item.Contract.Name }}
      </a>
    </clr-dg-cell>
    <clr-dg-cell>
      <a [routerLink]="['/contracts', item.Contract.Uid]">
        {{ item.Contract.Type }}
      </a>
    </clr-dg-cell>
  </clr-dg-row>

  <ng-container ngProjectAs="clr-dg-detail" *ngIf="extended">
    <clr-dg-detail *clrIfDetail="let item">
      <clr-dg-detail-header>
        <cds-icon shape="hashtag"></cds-icon>&nbsp;<a
          [routerLink]="['/contracts', item.Contract.Uid]"
          >{{ item.Contract.Nr }}</a
        >
        |
        <cds-icon shape="tag"></cds-icon>&nbsp;<a
          [routerLink]="['/contracts', item.Contract.Uid]"
          >{{ item.Contract.Name | uppercase }}</a
        >
      </clr-dg-detail-header>
      <clr-dg-detail-body>
        <div cds-text="subsection">
          <cds-icon shape="host-group"></cds-icon> Stationen
        </div>

        <app-stations-list-grid
          [extended]="false"
          [hiddenLocation]="true"
          [stations]="item.Stations"
          [loading]="loading"
        >
        </app-stations-list-grid>

        <ng-container *ngIf="item.Auftrag.length > 0">
          <p></p>

          <div cds-text="subsection">
            <cds-icon shape="file-group"></cds-icon>
            Auftragsbestätigungen
          </div>

          <app-documents-list-grid
            [documents]="item.Auftrag"
            [extended]="false"
          ></app-documents-list-grid>
        </ng-container>
      </clr-dg-detail-body>
    </clr-dg-detail>
  </ng-container>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
        >Verträge pro Seite</clr-dg-page-size
      >
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} von
      {{ pagination.totalItems }} Verträge
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
