<p></p>

<form clrForm [formGroup]="formDynamisch" (ngSubmit)="onSubmitTab($event)">
  <!-- <button class="btn btn-outline" (click)="onAddFieldClick()">
    <cds-icon shape="plus-circle"></cds-icon> feld hinzufügen
  </button> -->
  <button
    type="submit"
    class="btn btn-primary"
    [clrLoading]="submitTabState"
    style="margin-left: 10px"
  >
    Werte übernehmen
  </button>
  <br />
  <ng-container *ngIf="cabinet?.Template">
    <ng-container *ngIf="getItems().length > 0" id="dropDownContainer">
      <ng-container *ngFor="let itm of getItems()" id="inputContainer">
        <ng-container *ngIf="itm.ItemType === 'Date'">
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-3" for="inputContainer">
              {{ itm.Title }}
              <button
                *ngIf="itm.Info"
                [disabled]="!itm.Info"
                [style.visibility]="!itm.Info ? 'hidden' : 'visable'"
                class="btn btn-sm btn-link"
                (click)="onHelperClick(itm.Title, itm.Info)"
              >
                <cds-icon shape="info-standard"></cds-icon>
              </button>
            </label>
            <input
              clrInput
              type="date"
              [formControlName]="itm._id!"
              (ngModelChange)="inputChanged()"
              style="width: 100%"
              [style]="getStyle(itm)"
              class="clr-col-12 clr-col-md-9"
            />
          </clr-input-container>
        </ng-container>
        <ng-container *ngIf="itm.ItemType === 'Strings'">
          <clr-select-container>
            <label class="clr-col-12 clr-col-md-3" for="inputContainer">
              {{ itm.Title }}
              <button
                *ngIf="itm.Info"
                [disabled]="!itm.Info"
                [disabled]="!itm.Info"
                [style.visibility]="!itm.Info ? 'hidden' : 'visable'"
                class="btn btn-sm btn-link"
                (click)="onHelperClick(itm.Title, itm.Info)"
              >
                <cds-icon shape="info-standard"></cds-icon>
              </button>
            </label>
            <select
              class="clr-col-12 clr-col-md-8"
              clrSelect
              style="width: 100%"
              [style]="getStyle(itm)"
              [formControlName]="itm._id!"
              (ngModelChange)="inputChanged()"
            >
              <option
                style="width: 100%"
                *ngFor="let ctlOption of itm.Selection"
                [ngValue]="ctlOption"
              >
                {{ ctlOption }}
              </option>
            </select>
          </clr-select-container>
        </ng-container>
        <ng-container
          *ngIf="itm.ItemType === 'String' || itm.ItemType === 'Number'"
        >
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-3" for="inputContainer">
              {{ itm.Title }}
              <button
                *ngIf="itm.Info"
                [disabled]="!itm.Info"
                [style.visibility]="!itm.Info ? 'hidden' : 'visable'"
                class="btn btn-sm btn-link"
                (click)="onHelperClick(itm.Title, itm.Info)"
              >
                <cds-icon shape="info-standard"></cds-icon>
              </button>
            </label>
            <input
              style="width: 100%"
              [style]="getStyle(itm)"
              class="clr-col-12 clr-col-md-9"
              clrInput
              [formControlName]="itm._id!"
              (ngModelChange)="inputChanged()"
            />
          </clr-input-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <br />
    <!-- <div class="appitem"></div> -->
    <br />
    <!-- <p></p>
    <button class="btn btn-outline" (click)="onAddFieldClick()">
      <cds-icon shape="plus-circle"></cds-icon> feld hinzufügen
    </button> -->
    <button
      type="submit"
      class="btn btn-primary"
      [clrLoading]="submitTabState"
      style="margin-left: 10px"
    >
      Werte übernehmen
    </button>
  </ng-container>
</form>

<!-- popup-windows -->

<clr-modal [(clrModalOpen)]="onHelper">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onHelper = false">
      Ok
    </button>
  </div>
</clr-modal>
