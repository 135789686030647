import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ClrLoadingState } from '@clr/angular';
import { Observable } from 'rxjs';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { BaseImage, Evaluation } from 'src/app/models/Evaluation';

@Component({
  selector: 'app-risks-tab-images-evaluation',
  templateUrl: './risks-tab-images-evaluation.component.html',
  styleUrls: ['./risks-tab-images-evaluation.component.css'],
})
export class RiskTabEvaluationImagesComponent
  implements OnInit, AfterViewInit, OnChanges
{
  evaluation = { Images: [] } as unknown as Evaluation;
  @Input() evaluation$ =
    new Observable<Evaluation>() as Observable<Evaluation> | null;
  @Output() updated = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() init = new EventEmitter();

  dialogAddFile = false;
  title = '';
  message = '';
  imgViewSrc = '';
  dialogViewImg = false;

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
  ) {
    if (this.evaluation$) {
      this.evaluation$.subscribe((evaluation) => {
        this.evaluation = evaluation;
        this.init.emit(this);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['evaluation$']) {
      this.evaluation$?.subscribe((evaluation) => {
        this.evaluation = evaluation;
      });
    }
  }

  onDialogAddFileClosing() {
    this.dialogAddFile = false;
  }

  onAddFileClick() {
    this.dialogAddFile = true;
    return false;
  }

  getThumbImage(image: BaseImage) {
    const str64 = String.fromCharCode.apply(null, image.Thumb.data);
    return `data:${image.MIME};base64,${btoa(str64)}`;
  }

  showImage(image: BaseImage) {
    this.service
      .loadEvaluationImage(this.evaluation._id, image._id)
      .subscribe((result) => {
        this.imgViewSrc = `data:${image.MIME};base64,${result?.data}`;
        this.dialogViewImg = true;
      });
  }

  ngOnInit() {
    this.init.emit(this);
  }

  ngAfterViewInit() {
    if (this.evaluation$) {
      this.evaluation$?.subscribe((evaluation) => {
        this.evaluation = evaluation;
      });
    }
  }

  onUpdateFile(evaluation: any) {
    this.submitTabState = ClrLoadingState.LOADING;
    console.log('onUpdateFile()', evaluation);

    const fileDat = evaluation as {
      file: File;
      fileName: string;
    };
    if (fileDat.file && this.evaluation) {
      this.service
        .postEvaluationImage(fileDat.file, fileDat.fileName, this.evaluation)
        .subscribe((newEvaluation) => {
          this.evaluation = newEvaluation;
          this.updated.emit(this.evaluation);
          this.submitTabState = ClrLoadingState.SUCCESS;
        });
    }
    // this.submitTabState = ClrLoadingState.DEFAULT;
  }

  onDeleteFile(control: any) {
    this.submitTabState = ClrLoadingState.LOADING;
    console.log(`onDeleteFile control?._id : ${control?._id}`);
    this.service
      .deleteEvaluationImage(this.evaluation._id, control?._id)
      .subscribe((e) => {
        this.evaluation = e as Evaluation;
        this.updated.emit(this.evaluation);
        this.submitTabState = ClrLoadingState.SUCCESS;
      });
    // this.submitTabState = ClrLoadingState.DEFAULT;
  }
}
