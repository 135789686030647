import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  map,
  merge,
  Observable,
  of,
  reduce,
  Subject,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cabinet, LowCabinet } from '../models/Cabinet';
import { LowTemplate, Template } from '../models/Template';
import { Evaluation } from '../models/Evaluation';
import { Key, NgxIndexedDBService } from 'ngx-indexed-db';
import { Store } from '@ngrx/store';
import { selectProfil } from '../views/users/+profil/profil.selectors';

@Injectable({
  providedIn: 'root',
})
export class AssessmentStoreService {
  public isOnline$ = new BehaviorSubject<boolean>(window.navigator.onLine);
  public isInSync$ = new BehaviorSubject<boolean>(false);

  private apiUrl = environment.riskApi;
  // private offlineUpdatesAvailable = false;
  private updatedCabinetsDone = true;
  private updatedTemplatesDone = true;
  private updatedEvaluationsDone = true;
  private createdCabinetsDone = true;
  private createdTemplatesDone = true;
  private createdEvaluationsDone = true;
  private deletedCabinetsDone = true;
  private deletedTemplatesDone = true;
  private deletedEvaluationsDone = true;

  // ---------- newUpdated Cabinets ----------- //
  // ---------- deleted Cabinets -------------- //
  // ---------- newUpdated Evaluations -------- //
  // ---------- deleted Evaluations ----------- //
  // ---------- newUpdated Templates ---------- //
  // ---------- deleted Templates ------------- //
  constructor(
    private store: Store,
    private dbService: NgxIndexedDBService,
    private http: HttpClient,
  ) {
    this.listenToOnlineStatus();
  }

  listenToOnlineStatus(): void {
    console.info('listen to "online-status" for risk service');
    window.addEventListener('online', () => this.isOnline$.next(true));
    window.addEventListener('online', () => this.checkForOfflineData);
    window.addEventListener('offline', () => this.isOnline$.next(false));
    console.info('listen to "online-status" also for checking updates');
  }

  checkForOfflineData(): void {
    if (this.offlineUpdates()) {
      this.isInSync$.next(true);
      // ----------------- new Cabinets ----------- //
      if (!this.createdCabinetsDone) {
        this.dbService
          .getAll<LowCabinet>('Cabinet_created')
          .subscribe((result) =>
            this.http
              .post<Cabinet[]>(`${this.apiUrl}/cabinets/amount`, result)
              .pipe(
                map((cabinets) => {
                  console.log(
                    `SYNC:cabinets_created result[${cabinets.length}]`,
                  );
                  if (result.length == cabinets.length) {
                    const ids = result
                      .filter((c) => c._id !== undefined)
                      .map((c) => c._id) as Key[];
                    console.log(`SYNC:cabinets_created ids:`, ids);
                    this.dbService.bulkDelete('Cabinet_created', ids);
                  }
                  this.createdCabinetsDone = true;
                }),
                // catchError((error) =>
                //   of(CabinetActions.loadCabinetsFailure({ error })),
                // ),
              ),
          );
      }
      // ------------- updated Cabinets ----------- //
      if (!this.updatedCabinetsDone) {
        this.dbService
          .getAll<{ _id: string; imgFile: File }>('Cabinet_updated')
          .subscribe((resultSet) => {
            const idsCabinets = resultSet
              .filter((c) => c._id !== undefined && c.imgFile == undefined)
              .map((c) => c._id);
            const cobinetsWithImgs = resultSet.filter(
              (c) => c._id !== undefined && c.imgFile !== undefined,
            );
            this.dbService
              .bulkGet('Cabinet', idsCabinets)
              .subscibe((cabinets: unknown) => {
                this.http
                  .patch<Cabinet[]>(`${this.apiUrl}/cabinets/amount`, cabinets)
                  .pipe(
                    map((httpCabinets) => {
                      console.log(
                        `SYNC:cabinets_updated result[${httpCabinets.length}]`,
                      );
                      if ((cabinets as []).length == httpCabinets.length) {
                        console.log(
                          `SYNC:cabinets_created [${httpCabinets.length}] ids:`,
                        );
                        this.dbService.bulkDelete(
                          'Cabinet_updated',
                          idsCabinets,
                        );
                      }
                      this.updatedCabinetsDone = true;
                    }),
                    // catchError((error) =>
                    //   of(CabinetActions.loadCabinetsFailure({ error })),
                    // ),
                  );
              });
            cobinetsWithImgs.forEach((imgCabinet) => {
              this.dbService
                .getByID('Cabinet', imgCabinet._id)
                .subscribe((localCabinet) => {
                  const formData = new FormData();
                  formData.append('file', imgCabinet.imgFile);
                  formData.append('fileName', imgCabinet.imgFile.name);
                  formData.append(
                    'cabinet',
                    JSON.stringify(localCabinet as Cabinet),
                  );
                  this.http
                    .post(`${this.apiUrl}/cabinets/images/`, formData, {
                      reportProgress: true,
                      observe: 'events',
                    })
                    .subscribe((event) => {
                      if (event.type == HttpEventType.Response) {
                        // if (typeof event.body == typeof HttpErrorResponse) {
                        //   CabinetActions.loadCabinetsFailure({
                        //     error: event.body as HttpErrorResponse,
                        //   });
                        // }
                        // if (event.body !== null) {
                        //   CabinetActions.loadCabinetSuccess({
                        //     data: event.body as Cabinet,
                        //   });
                        // }
                      }
                    });
                });
            });
          });
      }
      // ---------- deleted Cabinets -------------- //
      if (!this.deletedCabinetsDone) {
        this.dbService
          .getAll<{ _id: string }>('Cabinet_deleted')
          .subscribe((result) => {
            console.log(`SYNC:Cabinet_deleted result[${result.length}]`);
            result.forEach((idObj) => {
              this.http
                .delete<Cabinet>(`${this.apiUrl}/cabinets/${idObj._id}`)
                .pipe(
                  map((cabinets) => {
                    // CabinetActions.deleteCabinetSuccess({ id: idObj._id });
                    this.dbService.deleteByKey('Cabinet_deleted', idObj._id);
                    this.deletedCabinetsDone = true;
                  }),
                  // catchError((error) =>
                  //   of(CabinetActions.deleteCabinetFailure({ error })),
                  // ),
                );
            });
          });
      }
      // ---------- newUpdated Evaluations -------- //
      // ---------- deleted Evaluations ----------- //
      // ---------- newUpdated Templates ---------- //
      // ---------- deleted Templates ------------- //
      // this.offlineUpdatesAvailable = false; // alles abgearbeitet ???
    }
    // ------------ newUpdated Cabinets ----------- //
    // ------------ deleted Cabinets -------------- //
    // ------------ newUpdated Evaluations -------- //
    // ------------ deleted Evaluations ----------- //
    // ------------ newUpdated Templates ---------- //
    // ------------ deleted Templates ------------- //
  }

  offlineUpdates() {
    return (
      !this.updatedCabinetsDone ||
      !this.updatedTemplatesDone ||
      !this.updatedEvaluationsDone ||
      !this.deletedCabinetsDone ||
      !this.deletedTemplatesDone ||
      !this.deletedEvaluationsDone ||
      !this.createdCabinetsDone ||
      !this.createdTemplatesDone ||
      !this.createdEvaluationsDone
    );
  }

  syncFinished() {
    const syncFinished =
      this.updatedCabinetsDone &&
      this.updatedTemplatesDone &&
      this.updatedEvaluationsDone &&
      this.deletedCabinetsDone &&
      this.deletedTemplatesDone &&
      this.deletedEvaluationsDone &&
      this.createdCabinetsDone &&
      this.createdTemplatesDone &&
      this.createdEvaluationsDone;
    // if (syncFinished) this.offlineUpdatesAvailable = false;
    this.isInSync$.next(syncFinished);
  }

  // ------------------ Cabinet ------------------- //
  // ------------------ Cabinet (Multi) ----------- //

  loadCabinets(): Subject<Cabinet[]> {
    const result: Subject<Cabinet[]> = new Subject<Cabinet[]>();
    console.log(`GET /cabinets/all/ `);
    this.dbService.getAll<Cabinet>('Cabinet').subscribe((c) => {
      result.next(c);
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .get<Cabinet[]>(`${this.apiUrl}/cabinets/all/`)
        .subscribe((cabinets) => {
          this.dbService.clear('Cabinet');
          this.dbService
            .bulkPut<Cabinet>('Cabinet', cabinets)
            .subscribe((onlineRes) => {
              console.log(
                `OFFLINE PATCH /cabinets/all/ => get all Cabinets Key:`,
                onlineRes,
              );
            });
          result.next(cabinets);
        });
    }
    return result;
  }

  // ------------------ Cabinet (Single) ---------- //
  loadCabinet(id: string): Subject<Cabinet> {
    console.log(`GET /cabinets/${id}`);
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    this.dbService.getByID('Cabinet', id).subscribe((c) => {
      const cabinet = c as Cabinet;
      result.next(cabinet);
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .get<Cabinet>(`${this.apiUrl}/cabinets/${id}`)
        .subscribe((cabinet) => {
          result.next(cabinet);
          this.dbService.update<Cabinet>('Cabinet', cabinet);
        });
    }
    return result;
  }

  postCabinet(cabinet: LowCabinet): Subject<Cabinet> {
    console.log('POST /cabinets/ ');
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .post<Cabinet>(`${this.apiUrl}/cabinets/`, cabinet)
        .subscribe((cabinet) => {
          result.next(cabinet);
          this.dbService.update<Cabinet>('Cabinet', cabinet);
        });
    } else {
      this.createdCabinetsDone = false;
      this.dbService.update('Cabinet_created', cabinet).subscribe((offline) => {
        offline._id = '0';
        console.log(`OFFLINE POST /cabinets/:${offline._id}/`);
        result.next(offline as Cabinet);
      });
    }
    return result;
  }

  patchCabinet(cabinet: Cabinet): Subject<Cabinet> {
    console.log(`PATCH /cabinets/:${cabinet._id}`);
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .patch<Cabinet>(`${this.apiUrl}/cabinets/${cabinet._id}`, cabinet)
        .subscribe((online) => {
          result.next(online);
          this.dbService.update<Cabinet>('Cabinet', online);
        });
    } else {
      this.updatedCabinetsDone = false;
      this.dbService.update('Cabinet', cabinet).subscribe((offline) => {
        result.next(offline);
      });
      this.dbService.update('Cabinet_updated', { _id: cabinet._id });
    }
    return result;
  }

  deleteCabinet(id: string): Subject<Cabinet[]> {
    console.log(`DELETE /cabinets/:${id}`);
    const result: Subject<Cabinet[]> = new Subject<Cabinet[]>();
    this.dbService.delete('Cabinet', id).subscribe((deleted) => {
      console.log(`OFFLINE DELETE /cabinets/:${id}/`, deleted);
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .delete<Cabinet[]>(`${this.apiUrl}/cabinets/${id}`)
        .subscribe((cabinets) => {
          this.dbService.clear('Cabinet');
          this.dbService.bulkPut<Cabinet>('Cabinet', cabinets);
          result.next(cabinets);
        });
    } else {
      this.updatedCabinetsDone = false;
      this.dbService.delete('Cabinet', id).subscribe((deleted) => {
        console.log(`OFFLINE DELETE /cabinets/:${id}/`, deleted);
        this.dbService.update('Cabinet_deleted', { _id: id });
        this.dbService.getAll<Cabinet>('Cabinet').subscribe((c) => {
          result.next(c);
        });
      });
    }
    return result;
  }

  // -------------- Cabinet.Image ----------------- //
  // ------- Cabinet- & Evaluation images --------- //

  // TODO : offline?
  loadCabinetImage(cabinetId: string, imageId: string): Observable<any> {
    // if (this.isOnline$.value && !this.offlineUpdates()) {
    console.log(`GET /cabinets/images/${cabinetId}/${imageId}`);
    const res = this.http.get(
      `${this.apiUrl}/cabinets/images/${cabinetId}/${imageId}`,
    );
    // res.subscribe((image) => {
    //   this.dbService
    //     .bulkPut<Cabinet>('Cabinet', image)
    //     .subscribe((result) => {
    //       console.log(
    //         `OFFLINE PATCH /cabinets/all/ => get all Cabinets Key:`,
    //         result,
    //       );
    //     });
    //   console.log('GET /cabinets/images/ => file:', image);
    // });
    return res;
    // }
  }

  // TODO : offline?
  loadEvaluationImage(evaluationId: string, imageId: string): Observable<any> {
    // only online
    console.log(`GET /cabinets/images/${evaluationId}/${imageId}`);
    return this.http.get(
      `${this.apiUrl}/cabinets/images/${evaluationId}/${imageId}`,
    );
  }

  postCabinetImage(
    file: File,
    name: string,
    cabinet: Cabinet,
  ): Subject<Cabinet> {
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    console.log(`POST /cabinets/images/ File(${name}) Cabinet(${cabinet._id})`);
    if (this.isOnline$.value && !this.offlineUpdates()) {
      let resCabinet: Cabinet = cabinet;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', name);
      formData.append('cabinet', JSON.stringify(cabinet));
      this.http
        .post(`${this.apiUrl}/cabinets/images/`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            if (event.body !== null) {
              resCabinet = event.body as Cabinet;
              result.next(resCabinet);
              this.dbService.update<Cabinet>('Cabinet', resCabinet);
            }
          }
        });
    } else {
      this.updatedCabinetsDone = false;
      this.dbService.update('Cabinet', cabinet).subscribe((offlineResult) => {
        console.log(`OFFLINE POST /cabinets/:${offlineResult._id}/`);
        result.next(offlineResult);
      });
      this.dbService.update('Cabinet_updated', {
        _id: cabinet._id,
        imgFile: file,
      });
    }
    return result;
  }

  postEvaluationImage(
    file: File,
    name: string,
    evaluation: Evaluation,
  ): Subject<Evaluation> {
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    console.log(
      `POST /evaluations/images/ File(${name}) Evaluation(${evaluation._id})`,
    );
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`POST /evaluation/images/ FileName:[${name}]`);
      let resEvaluation: Evaluation = evaluation;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', name);
      formData.append('evaluation', JSON.stringify(evaluation));
      this.http
        .post(`${this.apiUrl}/evaluation/images/`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            if (event.body !== null) {
              resEvaluation = event.body as Evaluation;
              result.next(resEvaluation);
              this.dbService.update<Evaluation>('Evaluation', resEvaluation);
            }
          }
        });
    } else {
      let resEvaluation: Evaluation = evaluation;
      this.updatedEvaluationsDone = false;
      this.dbService.update('Evaluation', evaluation).subscribe((offline) => {
        resEvaluation = offline;
        console.log(`OFFLINE POST /evaluations/:${offline._id}/`);
        result.next(resEvaluation);
      });
      this.dbService.update('Evaluation_updated', {
        _id: evaluation._id,
        imgFile: file,
      });
    }
    return result;
  }

  deleteCabinetImage(cabinetId: string, id: string): Subject<Cabinet> {
    console.log(`DELETE /cabinets/images/${cabinetId}/${id}`);
    const result: Subject<Cabinet> = new Subject<Cabinet>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .delete<Cabinet>(`${this.apiUrl}/cabinets/images/${cabinetId}/${id}`)
        .subscribe((cabinet) => {
          result.next(cabinet);
          this.dbService.update<Cabinet>('Cabinet', cabinet);
        });
    } else {
      this.dbService
        .getByID<Cabinet>('Cabinet', cabinetId)
        .subscribe((cabinet) => {
          cabinet;
          const img = cabinet.Images?.find((i) => i._id == id);
          if (img) {
            const index = cabinet.Images!.indexOf(img);
            if (index > -1) {
              cabinet.Images!.splice(index, 1); // 2nd parameter means remove one item only
              this.dbService.update<Cabinet>('Cabinet', cabinet);
              this.dbService.update<{ _id: string; imgId: string }>(
                'CabinetImage_deleted',
                { _id: cabinetId, imgId: id },
              );
              result.next(cabinet);
            }
          }
        });
    }
    return result;
  }

  deleteEvaluationImage(evaluationId: string, id: string): Subject<Evaluation> {
    console.log(`DELETE /evaluation/images/${evaluationId}/${id}`);
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .delete<Evaluation>(
          `${this.apiUrl}/evaluation/images/${evaluationId}/${id}`,
        )
        .subscribe((evaluation) => {
          result.next(evaluation);
          this.dbService.update<Evaluation>('Evaluation', evaluation);
        });
    } else {
      this.dbService
        .getByID<Evaluation>('Evaluation', evaluationId)
        .subscribe((evaluation) => {
          evaluation;
          const img = evaluation.Images?.find((i) => i._id == id);
          if (img) {
            const index = evaluation.Images!.indexOf(img);
            if (index > -1) {
              evaluation.Images!.splice(index, 1); // 2nd parameter means remove one item only
              this.dbService.update<Evaluation>('Evaluation', evaluation);
              this.dbService.update<{ _id: string; imgId: string }>(
                'EvaluationImage_deleted',
                { _id: evaluationId, imgId: id },
              );
              result.next(evaluation);
            }
          }
        });
    }
    return result;
  }

  // --------------- Cabinet.ExcelFile ------------ //

  postCabinetExcelFile(
    file: File,
    fileName: string,
    template: Template,
  ): Subject<Cabinet[]> {
    console.log('POST /cabinets/exelfiles/');
    const result: Subject<Cabinet[]> = new Subject<Cabinet[]>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', fileName);
      formData.append('template', JSON.stringify(template));
      this.http
        .post(`${this.apiUrl}/cabinets/exelfiles/`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            if (event.body !== null) {
              const cabinets = event.body as Cabinet[];
              this.dbService.bulkPut<Cabinet>('Cabinet', cabinets);
              result.next(cabinets);
            }
          }
        });
    } else {
      // TODO Offline ???
    }
    return result;
  }

  // ------ Evaluation (Cabinet.Ergebnis)---------- //
  loadNewEvaluationsFor(
    cabinetId: string | null | undefined,
    tempalteId: string | null | undefined,
  ): Subject<Evaluation> {
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    if (cabinetId && tempalteId) {
      // ------------
      // let res: Observable<Evaluation> = new Observable<Evaluation>();
      if (this.isOnline$.value && !this.offlineUpdates()) {
        console.log(`online GET /evaluation/new/ `);
        this.http
          .get<Evaluation>(
            `${this.apiUrl}/evaluation/new/${cabinetId}/${tempalteId}`,
          )
          .subscribe((evaluation) => {
            result.next(evaluation);
            this.dbService
              .update<Evaluation>('Evaluation', evaluation)
              .subscribe((result) => {
                console.log(
                  `OFFLINE PATCH /evaluation/new/ => result:`,
                  result,
                );
              });
            console.log(`GET /evaluation/new/ => newId:`, evaluation._id);
          });
      } else {
        console.log('OFFLINE GET /evaluation/new/ => : TODO');

        this.dbService.getByID<Cabinet>('Cabinet', cabinetId).subscribe((c) => {
          this.dbService
            .getByID<Template>('Template', tempalteId)
            .subscribe((t) => {
              const evaluation = this.createEvaluatinFor(c, t);
              evaluation._id = this.createOfflineId();
              result.next(evaluation);
            });
        });
        // TODO result generieren!
        // const res1 = this.dbService.getAll<Evaluation>('Evaluation');
        // const res2 = this.dbService.getAll<Evaluation>('Evaluation_created');
        // res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
        // res.subscribe((cabinets) => {
        //   console.log(
        //     `OFFLINE GET /cabinets/all/ => get all Cabinets counted:`,
        //     cabinets.length,
        //   );
        // });
      }
      // ------------
      // console.log(
      //   `GET /evaluation/new/${cabinetId}/${tempalteId}`,
      // );
      // return this.http.get<Evaluation>(
      //   `${this.apiUrl}/evaluation/new/${cabinetId}/${tempalteId}`,
      // );
    } else {
      console.error(
        `GET /evaluation/new/:cabinetId/:tempalteId \n\tcabinetId should NOT be null!`,
      );
    }
    return result;
  }

  loadEvaluations(): Subject<Evaluation[]> {
    const result: Subject<Evaluation[]> = new Subject<Evaluation[]>();
    console.log('GET /evaluation/all/');
    this.dbService.getAll<Evaluation>('Evaluation').subscribe((e) => {
      result.next(e);
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .get<[Evaluation]>(`${this.apiUrl}/evaluation/all/`)
        .subscribe((evaluations) => {
          this.dbService.clear('Cabinet');
          this.dbService
            .bulkPut<Evaluation>('Evaluation', evaluations)
            .subscribe((offlineRes) => {
              console.log(
                `OFFLINE PATCH /evaluations/all/ => get all Evaluations Key:`,
                offlineRes,
              );
            });
          result.next(evaluations);
        });
    }
    return result;
  }

  loadEvaluation(id: string): Subject<Evaluation> {
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    console.log(`GET /evaluation/${id}`);
    this.dbService.getByID<Evaluation>('Evaluation', id).subscribe((e) => {
      result.next(e);
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .get<Evaluation>(`${this.apiUrl}/evaluation/${id}`)
        .subscribe((e) => {
          this.dbService
            .update<Evaluation>('Evaluation', e)
            .subscribe((offlineRes) => {
              console.log(
                `OFFLINE PATCH /evaluation/${id} offline:`,
                offlineRes,
              );
            });
          result.next(e);
        });
    }
    return result;
  }

  loadEvaluationsByCabinet(cabinetId: string): Subject<Evaluation[]> {
    const result: Subject<Evaluation[]> = new Subject<Evaluation[]>();
    console.log(`GET /evaluation/byCabinet/${cabinetId}`);
    this.dbService.getAll<Evaluation>('Evaluation').subscribe((e) => {
      const evaluations = e.filter(
        (evaluation) => evaluation.Cabinet._id == cabinetId,
      );
      if (evaluations) result.next(evaluations);
      else
        console.error(
          'loadEvaluationsByCabinet() didn`t find any offline-data',
        );
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .get<Evaluation[]>(`${this.apiUrl}/evaluation/byCabinet/${cabinetId}`)
        .subscribe((evaluations) => {
          this.dbService
            .bulkPut<Evaluation>('Evaluation', evaluations)
            .subscribe((offlineRes) => {
              console.log(
                `OFFLINE PATCH /evaluation/byCabinet/${cabinetId} Key:`,
                offlineRes,
              );
            });
          result.next(evaluations);
        });
    }
    return result;
  }

  patchEvaluation(evaluation: Evaluation): Subject<Evaluation> {
    const result: Subject<Evaluation> = new Subject<Evaluation>();
    console.log(`PATCH /evaluation/${evaluation._id}`);
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .patch<Evaluation>(
          `${this.apiUrl}/evaluation/${evaluation._id}`,
          evaluation,
        )
        .subscribe((httpEvaluation) => {
          console.log(`got result with Evaluation._id:`, httpEvaluation);
          this.dbService
            .update<Evaluation>('Evaluation', httpEvaluation)
            .subscribe((localEvaluations) => {
              console.log('OFFLINE PATCH /evaluation/:', localEvaluations);
            });
          result.next(httpEvaluation);
        });
    } else {
      this.updatedEvaluationsDone = false;
      this.dbService.update('Evaluation', evaluation).subscribe((offline) => {
        console.log(`OFFLINE PATCH /evaluation/:${offline._id}/`);
        result.next(offline as Evaluation);
      });
      this.dbService.update('Evaluation_updated', { _id: evaluation._id });
    }
    return result;
  }

  // TODO : ------------------ Offline-functions ----------

  getPdfEvaluation(id: string): Observable<any> {
    console.log(`GET /pdfresult/:id${id}`);
    return this.http.get(`${this.apiUrl}/evaluation/pdfresult/${id}`);
  }

  loadNewPdfFor(id: string): Observable<any> {
    console.log(`GET /pdfrequestfor/:id${id}`);
    return this.http.get(`${this.apiUrl}/evaluation/pdfrequestfor/${id}`);
  }

  getPdfEvaluationsFor(cabinetId: string): Observable<any> {
    console.log(`GET /evaluation/pdfresults/${cabinetId}`);
    return this.http.get(`${this.apiUrl}/evaluation/pdfresults/${cabinetId}`);
  }

  // ------------- Template (edit/create)---------- //

  /**
   * For TemplateActions "Load Template"
   * @param id Template-Id
   * @returns Subject<Template>
   */
  loadTemplate(id: string): Subject<Template> {
    const result: Subject<Template> = new Subject<Template>();
    console.log(`GET /templates/:${id}`);
    this.dbService.getByID<Template>('Template', id).subscribe((e) => {
      result.next(e);
    });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .get<Template>(`${this.apiUrl}/templates/${id}`)
        .subscribe((template) => {
          this.dbService
            .update<Template>('Template', template)
            .subscribe((offRes) => {
              console.log(`OFFLINE PATCH /templates/:${template._id}`, offRes);
            });
          result.next(template);
        });
    }
    return result;
  }

  /**
   * For TemplateActions "Load Template"
   * @param id Template-Id
   * @returns Subject<Template>
   */
  loadTemplates(): Subject<Template[]> {
    const result: Subject<Template[]> = new Subject<Template[]>();
    console.log('GET /templates/all/');
    // this.dbService.getAll<Template>('Template').subscribe((t) => {
    //   result.next(t);
    // });
    if (this.isOnline$.value && !this.offlineUpdates()) {
      this.http
        .get<Template[]>(`${this.apiUrl}/templates/all/`)
        .subscribe((templates) => {
          this.dbService.clear('Template');
          this.dbService
            .bulkPut<Template>('Template', templates)
            .subscribe((result) => {
              console.log(
                `OFFLINE PATCH /templates/all/ => Templates Key:`,
                result,
              );
            });
          result.next(templates);
        });
    } else {
      console.log('OFFLINE GET /templates/all/');
      const res1 = this.dbService.getAll<Template>('Template');
      const res2 = this.dbService.getAll<Template>('Template_created');
      const res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
      res.subscribe((templates) => {
        result.next(templates);
        console.log(
          `OFFLINE GET /templates/all/ => Templates counted:`,
          templates.length,
        );
      });
    }
    return result;
  }

  /**
   * For TemplateActions "Load Template Current"
   * @returns Observable<Template>
   */
  loadTemplateActual(): Subject<Template> {
    const res: Subject<Template> = new Subject<Template>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log(`GET /templates/`);
      this.http
        .get<Template>(`${this.apiUrl}/templates/`)
        .subscribe((template) => {
          this.dbService
            .update<Template>('Template', template)
            .subscribe((offRes) => {
              console.log(`OFFLINE PATCH /templates/:${template._id}`, offRes);
            });
          res.next(template);
        });
    } else {
      const lkas = this.dbService.getAll<Template>('Template');
      lkas.subscribe((template) => {
        const defTemp = template.reduce((a, b) => {
          if (a.createdAt > b.createdAt) return a;
          else return b;
        });
        console.log(`OFFLINE GET /templates/`, defTemp);
        res.next(defTemp);
      });
    }
    return res;
  }

  /**
   * For TemplateActions "Load Template Default"
   * @returns Observable<Template>
   */
  loadTemplateDefault(): Subject<Template> {
    const res: Subject<Template> = new Subject<Template>();
    if (this.isOnline$.value && !this.offlineUpdates()) {
      console.log('GET /templates/default/');
      this.http
        .get<Template>(`${this.apiUrl}/templates/default/`)
        .subscribe((template) => {
          this.dbService
            .update<Template>('Template', template)
            .subscribe((offRes) => {
              console.log(
                `OFFLINE PATCH /templates/default/:${template._id}`,
                offRes,
              );
            });
          res.next(template);
        });
    } else {
      this.dbService.getAll<Template>('Template').subscribe((template) => {
        const defTemp = template.reduce((a, b) => {
          if (a.createdAt < b.createdAt) return a;
          else return b;
        });
        console.log(`OFFLINE GET /templates/default/`, defTemp);
        res.next(defTemp);
      });
    }
    return res;
  }

  // TODO : ------------------ Offline-functions ----------
  postTemplate(tempalte: LowTemplate): Observable<Template> {
    console.log(`POST /templates/ -> new for Uid'${tempalte.Uid}'`);
    return this.http.post<Template>(`${this.apiUrl}/templates`, tempalte);
  }

  patchTemplate(template: Template): Observable<Template> {
    console.log(`PATCH /templates/:${template._id}`);
    return this.http.patch<Template>(
      `${this.apiUrl}/templates/${template._id}`,
      template,
    );
  }

  deleteTemplate(id: string): Observable<Template> {
    console.log(`DELETE /templates/:${id}`);
    return this.http.delete<Template>(`${this.apiUrl}/templates/${id}`);
  }

  createOfflineId(): string {
    return `offline_${new Date().getMilliseconds()}`;
  }
  //  ---------------------   Utils  ---------------------
  createEvaluatinFor(cabinet: Cabinet, template: Template): Evaluation {
    const defaultEvaluation: Evaluation = {
      Cabinet: cabinet,
      Template: template,
      Pruefung: [] as { ItemId: string; Value: object }[],
      Gefaehrdung: [] as { ItemId: string; Value: object }[],
      Page1: { Assesment: [] },
      Page2: {
        PruefItems: [],
        Reduktionsfaktor: '',
      },
      Images: [],
    } as unknown as Evaluation;
    const res = defaultEvaluation;
    template.Pruefung.forEach((itm) => {
      defaultEvaluation.Pruefung?.push({
        ItemId: itm._id,
        Value: {},
      });
    });
    template.Gefaehrdung.forEach((itm) => {
      defaultEvaluation.Gefaehrdung?.push({
        ItemId: itm._id,
        Value: {},
      });
    });
    return res;
  }
}
