import { NgModule } from '@angular/core';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';

const dbConfig: DBConfig = {
  name: 'MyDb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'Cabinet',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [
        { name: '_id', keypath: '_id', options: { unique: true } },
        { name: 'Uid', keypath: 'Uid', options: { unique: false } },
        { name: 'Template', keypath: 'Template', options: { unique: false } },
        {
          name: 'Anlage',
          keypath: 'Anlage',
          options: { unique: false, multiEntry: true },
        },
        {
          name: 'Images',
          keypath: 'Images',
          options: { unique: false, multiEntry: true },
        },
      ],
    },
    {
      store: 'Cabinet_deleted',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [{ name: '_id', keypath: '_id', options: { unique: true } }],
    },
    {
      store: 'Cabinet_updated',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [
        { name: '_id', keypath: '_id', options: { unique: true } },
        { name: 'imgFile', keypath: 'imgFile', options: { unique: false } },
      ],
    },
    {
      store: 'CabinetImage_deleted',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [
        { name: '_id', keypath: '_id', options: { unique: true } },
        { name: 'imgId', keypath: 'imgId', options: { unique: true } },
      ],
    },
    {
      store: 'Cabinet_importFiles',
      storeConfig: { keyPath: 'fileName', autoIncrement: true },
      storeSchema: [
        { name: 'fileName', keypath: 'fileName', options: { unique: true } },
        { name: 'excelFile', keypath: 'excelFile', options: { unique: false } },
      ],
    },
    {
      store: 'Cabinet_created',
      storeConfig: { keyPath: '_id', autoIncrement: true },
      storeSchema: [
        { name: 'Uid', keypath: 'Uid', options: { unique: false } },
        { name: 'Template', keypath: 'Template', options: { unique: false } },
        {
          name: 'Anlage',
          keypath: 'Anlage',
          options: { unique: false, multiEntry: true },
        },
        {
          name: 'Images',
          keypath: 'Images',
          options: { unique: false, multiEntry: true },
        },
      ],
    },
    {
      store: 'Evaluation',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [
        { name: '_id', keypath: '_id', options: { unique: true } },
        { name: 'Cabinet', keypath: 'Cabinet', options: { unique: false } },
        { name: 'Template', keypath: 'Template', options: { unique: false } },
        {
          name: 'Pruefung',
          keypath: 'Pruefung',
          options: { unique: false, multiEntry: true },
        },
        {
          name: 'Gefaehrdung',
          keypath: 'Gefaehrdung',
          options: { unique: false, multiEntry: true },
        },
        { name: 'Page1', keypath: 'Page1', options: { unique: false } },
        { name: 'Page2', keypath: 'Page2', options: { unique: false } },
        { name: 'PDFResult', keypath: 'PDFResult', options: { unique: false } },
        {
          name: 'Images',
          keypath: 'Images',
          options: { unique: false, multiEntry: true },
        },
        { name: 'createdAt', keypath: 'createdAt', options: { unique: false } },
        { name: 'updatedAt', keypath: 'updatedAt', options: { unique: false } },
      ],
    },
    {
      store: 'Evaluation_created',
      storeConfig: { keyPath: '_id', autoIncrement: true },
      storeSchema: [
        { name: 'Cabinet', keypath: 'Cabinet', options: { unique: false } },
        { name: 'Template', keypath: 'Template', options: { unique: false } },
        {
          name: 'Pruefung',
          keypath: 'Pruefung',
          options: { unique: false, multiEntry: true },
        },
        {
          name: 'Gefaehrdung',
          keypath: 'Gefaehrdung',
          options: { unique: false, multiEntry: true },
        },
        { name: 'Page1', keypath: 'Page1', options: { unique: false } },
        { name: 'Page2', keypath: 'Page2', options: { unique: false } },
        { name: 'PDFResult', keypath: 'PDFResult', options: { unique: false } },
        {
          name: 'Images',
          keypath: 'Images',
          options: { unique: false, multiEntry: true },
        },
        { name: 'createdAt', keypath: 'createdAt', options: { unique: false } },
        { name: 'updatedAt', keypath: 'updatedAt', options: { unique: false } },
      ],
    },
    {
      store: 'Evaluation_deleted',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [{ name: '_id', keypath: '_id', options: { unique: true } }],
    },
    {
      store: 'Evaluation_updated',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [
        { name: '_id', keypath: '_id', options: { unique: true } },
        { name: 'imgFile', keypath: 'imgFile', options: { unique: false } },
      ],
    },
    {
      store: 'Template',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [
        { name: '_id', keypath: '_id', options: { unique: true } },
        { name: 'Uid', keypath: 'Uid', options: { unique: false } },
        { name: 'Anlage', keypath: 'Anlage', options: { unique: false } },
        { name: 'Pruefung', keypath: 'Pruefung', options: { unique: false } },
        {
          name: 'Gefaehrdung',
          keypath: 'Gefaehrdung',
          options: { unique: false },
        },
        { name: 'createdAt', keypath: 'createdAt', options: { unique: false } },
        { name: 'updatedAt', keypath: 'updatedAt', options: { unique: false } },
      ],
    },
    {
      store: 'Template_deleted',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [{ name: '_id', keypath: '_id', options: { unique: true } }],
    },
    {
      store: 'Template_updated',
      storeConfig: { keyPath: '_id', autoIncrement: false },
      storeSchema: [{ name: '_id', keypath: '_id', options: { unique: true } }],
    },
  ],
};

@NgModule({
  imports: [NgxIndexedDBModule.forRoot(dbConfig)],
})
export class AssessmentOfflineModule {}
