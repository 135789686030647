import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Cabinet } from 'src/app/models/Cabinet';
import { ClrDatagridComparatorInterface, ClrLoadingState } from '@clr/angular';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { Observable, of } from 'rxjs';

class MyComparator implements ClrDatagridComparatorInterface<Cabinet> {
  constructor(
    private id: string,
    private service: AssessmentStoreService,
  ) {}

  compare(a: Cabinet, b: Cabinet) {
    const aIdxItm = a?.Anlage?.find(
      (itm: { ItemId: any }) => itm?.ItemId == this.id,
    ) as {
      ItemId: string;
      Value: object;
    };
    const bIdxItm = b?.Anlage?.find(
      (itm: { ItemId: any }) => itm?.ItemId == this.id,
    ) as {
      ItemId: string;
      Value: object;
    };
    let result = 0;
    if (aIdxItm?.Value == bIdxItm?.Value) result = 0;
    else if (aIdxItm?.Value < bIdxItm?.Value) result = -1;
    else result = 1;
    return result;
  }
}

@Component({
  selector: 'app-risks-list-grid',
  templateUrl: './risks-list-grid.component.html',
  styleUrls: ['./risks-list-grid.component.css'],
})
export class RisksListGridComponent implements OnInit {
  templateObserver$ = this.service.loadTemplateDefault();
  cabinetsObserver$ = null as unknown as Observable<Cabinet[]>;
  gridLoadingState$ = true;
  dialogAddOpen = false;
  dialogImportOpen = false;
  dialogDeleteOpen = false;
  selected = [] as Cabinet[];
  @ViewChild('gridchild') gridchild: any; // as ClrDatagrid;
  @ViewChild('listfilter') listfilter: any; // as ClrDatagrid;

  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['admin']));

  online = this.service.isOnline$;

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.templateObserver$.subscribe((res) => {
      if (!res) {
        console.warn('templates is undefined, load new templateObserver$');
        this.templateObserver$ = this.service.loadTemplateDefault();
      }
    });
    this.gridLoadingState$ = true;
    this.cabinetsObserver$ = this.service.loadCabinets();
    this.cabinetsObserver$.subscribe((cabines) => {
      this.gridLoadingState$ = false;
    });
  }

  getValues(valueObjects: { ItemId: string; Value: object }[]) {
    const list = [] as any[];
    try {
      valueObjects.forEach((x) => {
        const valueObject = {
          [`col${x.ItemId}`]: x?.Value,
          ItemId: x.ItemId,
        };
        list.push(valueObject);
      });
    } catch (err) {
      console.error(err);
    }
    return list;
  }

  addSortationFor(itemId: string): MyComparator {
    return new MyComparator(itemId, this.service);
  }

  isLoading() {
    return of(this.gridLoadingState$);
  }

  onBtnEditClick() {
    const cabinet = this.selected[0] as Cabinet;
    this.router.navigate(['review', cabinet._id], {
      relativeTo: this.activatedRoute,
    });
  }

  onDialogAddClick() {
    this.dialogAddOpen = false;
  }

  onBtnAddClick() {
    this.dialogAddOpen = true;
  }

  onBtnImportClick() {
    this.dialogImportOpen = true;
  }

  onDialogImportClick() {
    this.dialogImportOpen = false;
  }

  onDeleteSelected() {
    this.dialogDeleteOpen = true;
  }

  deleteSelected() {
    this.selected.map((s) => {
      this.cabinetsObserver$ = this.service.deleteCabinet(s._id);
    });
    this.dialogDeleteOpen = false;
  }
}
