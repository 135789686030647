import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ClrLoadingState } from '@clr/angular';
import { Cabinet } from 'src/app/models/Cabinet';
import { Item } from 'src/app/models/Item';
import { RiskTabCabinetComponent } from '../risks-tab-cabinet/risks-tab-cabinet.component';
import { RiskTabCabinetImagesComponent } from '../risks-tab-images-cabinet/risks-tab-images-cabinet.component';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-risks-cabinet-edition',
  templateUrl: './risks-cabinet-edition.component.html',
  styleUrls: ['./risks-cabinet-edition.component.css'],
})
export class RisksEditCabinetComponent implements OnInit, OnDestroy {
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  itmsAnlage$ = [] as Item[];
  cabinetId: string | null | undefined = null;
  cabinet: Cabinet | null = null;
  templateId: string | null | undefined = null;

  @ViewChild('anlageTab') tabAnlage = {} as RiskTabCabinetComponent;
  @ViewChild('imgTab') tabFotos = {} as RiskTabCabinetImagesComponent;

  submitAnlageState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitPruefungState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitGefaehrdungenState: ClrLoadingState = ClrLoadingState.DEFAULT;

  helper = false;
  text = '';

  onHelper(text: string) {
    this.helper = true;
    this.text = text.toLowerCase();
  }

  constructor(
    private service: AssessmentStoreService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params['id'] as string;
      this.service.loadCabinet(id).subscribe((cabinet) => {
        if (cabinet) this.cabinet = cabinet;
      });
    });
  }

  ngOnDestroy(): void {
    this.uploadSub$.unsubscribe();
  }

  file: File | null = null;
  excelFile: File | null = null;
  uploadProgress = 0;
  uploadSub$ = new Subscription();

  setCabinet(event: any) {
    if (event._id) {
      this.service.loadCabinet(event._id).subscribe((cabinet) => {
        this.cabinet = cabinet;
      });
    }
  }

  refresh(event: any) {
    if (this.cabinet) {
      this.service
        .loadCabinet(this.cabinet._id)
        .subscribe((cabinet) => (this.cabinet = cabinet));
      if (this.tabAnlage && this.cabinet) {
        this.tabAnlage.cabinet = this.cabinet;
      }
      if (this.tabFotos && this.cabinet) {
        this.tabFotos.cabinet = this.cabinet;
      }
    }
  }

  initAnlage($event: any) {
    try {
      this.tabAnlage = $event as RiskTabCabinetComponent;
      this.tabAnlage.cabinet = this.cabinet; // new
    } catch (err) {
      console.error(err);
    }
  }

  initImages($event: any) {
    try {
      this.tabFotos = $event as RiskTabCabinetImagesComponent;
      this.tabFotos.cabinet = this.cabinet; // new
    } catch (err) {
      console.error(err);
    }
  }
}
