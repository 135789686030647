import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClrLoadingState } from '@clr/angular';
import { AuthService } from 'src/app/services/auth.service';
import { LowTemplate, Template } from 'src/app/models/Template';
import { Item } from 'src/app/models/Item';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { Evaluation } from 'src/app/models/Evaluation';
import { RiskTabEditTemplateComponent } from '../risks-tab-template/risks-tab-template.component';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-risks-template-edition',
  templateUrl: './risks-template-edition.component.html',
  styleUrls: ['./risks-template-edition.component.css'],
})
export class RisksEditTemplateComponent implements OnInit {
  template$ = new Observable<Template>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  evaluations$ = new Observable<[Evaluation]>();
  evaluation$ = undefined as unknown as Observable<Evaluation>;

  @Output() itmsAnlage$ = [] as Item[];
  @Output() itmsPruefung$ = [] as Item[];
  @Output() itmsGefaehrdung$ = [] as Item[];
  authService$ = {} as AuthService;

  template = {} as Template;
  templateId = '';

  @ViewChild('anlageTab') tabAnlage =
    undefined as unknown as RiskTabEditTemplateComponent;
  @ViewChild('pruefungTab') tabPruefung =
    undefined as unknown as RiskTabEditTemplateComponent;
  @ViewChild('gefaehrdungTab') tabGefaehrdung =
    undefined as unknown as RiskTabEditTemplateComponent;
  isManager$ = this.store.select(selectProfilRoles(['admin']));
  submitAnlageState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitPruefungState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitGefaehrdungenState: ClrLoadingState = ClrLoadingState.DEFAULT;
  helper = false;
  text = '';

  onHelper(text: string) {
    this.helper = true;
    this.text = text.toLowerCase();
  }

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.template$ = this.service.loadTemplate(params['id']);
      this.template$?.subscribe((tmpTemplate) => {
        this.itmsAnlage$ = [];
        tmpTemplate.Anlage.forEach((itm) => {
          this.itmsAnlage$.push(this.copyItem(itm));
        });
        this.itmsPruefung$ = [];
        tmpTemplate.Pruefung.forEach((itm) => {
          this.itmsPruefung$.push(this.copyItem(itm));
        });
        this.itmsGefaehrdung$ = [];
        tmpTemplate.Gefaehrdung.forEach((itm) => {
          this.itmsGefaehrdung$.push(this.copyItem(itm));
        });
        this.template = tmpTemplate;
      });
    });
  }

  copyItem(itm: Item): Item {
    return {
      _id: itm._id,
      Title: itm.Title,
      ItemIndex: itm.ItemIndex,
      IsPrimary: itm.IsPrimary,
      ItemType: itm.ItemType,
      Selection: itm.Selection,
      Fixed: itm.Fixed,
      Info: itm.Info,
      Name: itm.Name,
      Value: itm.Value,
    } as Item;
  }

  refresh(event: any) {
    this.template$ = this.service.loadTemplate(this.template._id);
    // this.template$ = this.store.select(selectTemplateCurrent);
    this.template$?.subscribe((template) => {
      this.template = template;
      this.itmsPruefung$ = template.Pruefung;
      this.itmsGefaehrdung$ = template.Gefaehrdung;
      switch (
        (event as RiskTabEditTemplateComponent).tabFor$.toLocaleLowerCase()
      ) {
        case 'anlage':
          this.tabAnlage?.refresh(this.itmsAnlage$);
          break;
        case 'pruefung':
          this.tabPruefung?.refresh(this.itmsPruefung$);
          break;
        case 'gefaehrdung':
          this.tabGefaehrdung?.refresh(this.itmsGefaehrdung$);
          break;
      }
    });
  }

  onSaveClick() {
    this.service.postTemplate({
      Anlage: this.itmsAnlage$,
      Pruefung: this.itmsPruefung$,
      Gefaehrdung: this.itmsGefaehrdung$,
    } as LowTemplate);
  }

  /**
   * Tab in Parent will be set
   * @param $event contains the 'Tab' for the parant (this) ...
   */
  initAnlage($event: any) {
    try {
      this.tabAnlage = $event as RiskTabEditTemplateComponent;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Tab in Parent will be set
   * @param $event contains the 'Tab' for the parant (this) ...
   */
  initPruefung($event: any) {
    try {
      this.tabPruefung = $event as RiskTabEditTemplateComponent;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Tab in Parent will be set
   * @param $event contains the 'Tab' for the parant (this) ...
   */
  initGefaehrdung($event: any) {
    try {
      this.tabGefaehrdung = $event as RiskTabEditTemplateComponent;
    } catch (err) {
      console.error(err);
    }
  }

  // initFiles($event: any) {
  //   try {
  //     this.tabFotos = $event as RiskTabFilesComponent;
  //   } catch (err) {
  //     console.error('FEHLER', err);
  //   }
  // }
}
